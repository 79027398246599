import OBJ from '../../_obj';
import { Util } from '../../_util';
import { gsap } from "gsap";

const processPage = () => {
  const obj = {

    init() {
    	const A = this;

			A.tabNavClone();

    },

		loading(){

		},


		setSelector(){
			this.$body = $('body');


		},


		tabNavClone(){
			const $nav = $('.tab-nav');
			const btnWrapCode = '<div class="tab-nav-fixed-wrap"></div>';

			$('.main-content').append(btnWrapCode);
			$('.tab-nav-fixed-wrap').append($nav.clone());

			$(window).on('scroll', (e) => {
				const $this = $(e.currentTarget);
				const val = $this.scrollTop();
				const footerOffset = $('.l-footer').offset().top;
				const wH = $this.height();

				if(100 < val){
					$('.tab-nav-fixed-wrap .tab-nav').addClass('is-active');
				} else {
					$('.tab-nav-fixed-wrap .tab-nav').removeClass('is-active');
				}

				if(footerOffset - wH < val){
					$('.tab-nav-fixed-wrap .tab-nav').addClass('is-footer-hidden');
				} else {
					$('.tab-nav-fixed-wrap .tab-nav').removeClass('is-footer-hidden');
				}
			})
		},



  }

  obj.init();
}


export {processPage};