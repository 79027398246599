import FORM_OBJ from './_formObj';
export default {
	storageObj: {},
	init(){
		// console.log('セッションだよ');
		this.objSet();
		this.set();
	},

	post(_el){
		// console.log(_el.val());
		sessionStorage.setItem(_el.attr('name'), _el.val());
	},

	objSet(){
		this.keyArray.forEach((el,i) => {
			if(sessionStorage.getItem(el)){
				// console.log(el,sessionStorage.getItem(el))
				this.storageObj[el] = sessionStorage.getItem(el);
			}
			// console.log(this.storageObj);
		});

		// this.storageObj.fileNumber = sessionStorage.getItem('fileNumber');
		// console.log(this.storageObj);
	},

	set(_storage){

		const _self = this;


		this.keyArray.forEach((el,i) => {

			if(_self.storageObj[el]){
				const tag = $('*[name="'+ el +'"]').prop('tagName');

				FORM_OBJ[el] = _self.storageObj[el];


				if(tag == 'SELECT'){
					const val = _self.storageObj[el];

					if(el == 'expertise' || el == 'timeStamp' || el == 'timeStampSrt' || el == 'selfRole' || el == 'webData'){
						// console.log(val)
						setTimeout(() => {
							$('#'+el).val(val).trigger('change');
						},800);
					} else if(el == 'talkNumber1'){
						setTimeout(() => {
							$('#'+el).val(val).trigger('change');
						},500);
					} else if(el == 'talkNumber2'){
						setTimeout(() => {
							$('#'+el).val(val).trigger('change');
						},500);
					} else {
						$('#'+el).val(_self.storageObj[el]);
					}




				} else if(tag == 'TEXTAREA'){
					$('#'+el).text(_self.storageObj[el]);
				} else if(tag == 'INPUT'){
					if($('#'+el).attr('type') == 'text'){
						$('#'+el).val(_self.storageObj[el]);
						// console.log(_self.storageObj[el]);
					} else {
						$('*[name="'+ el +'"][value="'+ _self.storageObj[el] +'"]').prop('checked', true);
					}
				}


				if(el == 'inputSelect'){
					$('*[name="'+ el +'"][value="'+ _self.storageObj[el] +'"]').trigger('change');
				}

				if(el == 'fileNumber'){
					$('#'+el).trigger('change');
				}

				if(el == 'speakerNumber1'){
					$('#'+el).trigger('change');
				}
				if(el == 'speakerNumber2'){
					$('#'+el).trigger('change');
				}
				// if(el == 'fileNumber'){
				// 	$('#'+el).trigger('change');
				// }
				if(el == 'plan'){
					if($('#'+el).val() != null && !isNaN($('#'+el).val())){
						$('#'+el).parents('.p-select').addClass('selected');
					}
				}
				if(el == 'format1'){
					if($('#'+el).val() != null && !isNaN($('#'+el).val())){
						$('#'+el).parents('.p-select').addClass('selected');
					}
				}
				if(el == 'format2'){
					if($('#'+el).val() != null && !isNaN($('#'+el).val())){
						$('#'+el).parents('.p-select').addClass('selected');
					}
				}
				// if(el == 'talkNumber1'){
				// 	$('#'+el).trigger('change');
				// }
				// if(el == 'talkNumber2'){
				// 	$('#'+el).trigger('change');
				// }

				if(el == 'history'){
					$('#'+el).trigger('change');
				}


				// console.log(FORM_OBJ.fileNumber,FORM_OBJ.voiceDetail,FORM_OBJ.fileName1,FORM_OBJ.fileName2)


			}
		});

		// console.log($('#estimate').val());
	},

	keyArray: [
		// step1

		'fileNumber',
		'voiceDetail',
		'fileName1',
		// 'speakerNumber1',
		'ttime1_s_h',
		'ttime1_s_m',
		'ttime1_s_s',
		'startWord1',
		'ttime1_e_h',
		'ttime1_e_m',
		'ttime1_e_s',
		'endWord1',
		'keyword1',

		'fileName2',
		// 'speakerNumber2',
		'ttime2_s_h',
		'ttime2_s_m',
		'ttime2_s_s',
		'startWord2',
		'ttime2_e_h',
		'ttime2_e_m',
		'ttime2_e_s',
		'endWord2',
		'keyword2',

		'inputSelect',

		// step2
		'preferredDate',
		'plan',


		// step3
		'format1',
		'format2',

		'talkNumber1',

		'peopleName1_1',
		'peopleTime_h_1_1',
		'peopleTime_m_1_1',
		'peopleTime_s_1_1',
		'peopleWord1_1',

		'peopleName1_2',
		'peopleTime_h_1_2',
		'peopleTime_m_1_2',
		'peopleTime_s_1_2',
		'peopleWord1_2',

		'peopleName1_3',
		'peopleTime_h_1_3',
		'peopleTime_m_1_3',
		'peopleTime_s_1_3',
		'peopleWord1_3',

		'peopleName1_4',
		'peopleTime_h_1_4',
		'peopleTime_m_1_4',
		'peopleTime_s_1_4',
		'peopleWord1_4',

		'peopleName1_5',
		'peopleTime_h_1_5',
		'peopleTime_m_1_5',
		'peopleTime_s_1_5',
		'peopleWord1_5',

		'talkNumber2',

		'peopleName2_1',
		'peopleTime_h_2_1',
		'peopleTime_m_2_1',
		'peopleTime_s_2_1',
		'peopleWord2_1',

		'peopleName2_2',
		'peopleTime_h_2_2',
		'peopleTime_m_2_2',
		'peopleTime_s_2_2',
		'peopleWord2_2',

		'peopleName2_3',
		'peopleTime_h_2_3',
		'peopleTime_m_2_3',
		'peopleTime_s_2_3',
		'peopleWord2_3',

		'peopleName2_4',
		'peopleTime_h_2_4',
		'peopleTime_m_2_4',
		'peopleTime_s_2_4',
		'peopleWord2_4',

		'peopleName2_5',
		'peopleTime_h_2_5',
		'peopleTime_m_2_5',
		'peopleTime_s_2_5',
		'peopleWord2_5',

		'expertise',
		'timeStamp',
		'timeStamp1Details',
		'timeStamp2Details',
		'timeStampSrt',
		'selfRole',
		'webData',
		'holidayDelivery',
		'returnData',

		// step4
		'history',
		'corporate',
		'name_1',
		'name_2',
		'read_1',
		'read_2',
		'postal',
		'address',
		'phone',
		'mail_address',

		// step5
		'atena',
		'seikyu',
		'mitsumori',
		'nohin',
		'ryoshu',

		// step6
		'mail_contents',
		'concent',
		'caution_concent',


		'speakerNumber1',
		'speakerNumber2',

		'estimate'
	]
}