export default {
	wasya: [
		0,30,30,30 + (22 * 1), 30 + (22 * 2), 30 + (22 * 3)
	],
	wasyaNum: 60, //分
	expertise: 100, //分
	timeStamp: 30, //分
	timeStampSrt: 60, //分
	selfRole: 30, //分
	webData: 1000, //データ
	holidayDelivery: 2000, //注文
	returnData: 800, //注文
	document: 99, //書類の郵送
}