var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "post-wrap single-fade to-up" }, [
    _c("div", { staticClass: "post-block" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "post-block__list" },
        _vm._l(_vm.infoData, function (data, infoIndex) {
          return _c(
            "li",
            { key: infoIndex, staticClass: "post-block__list-item" },
            [
              _c("a", { staticClass: "u-hv-o", attrs: { href: data.link } }, [
                _c("p", { staticClass: "post-block__date u-ff-hel" }, [
                  _vm._v(_vm._s(data.date)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "post-block__ttl" }, [
                  _vm._v(_vm._s(data.title)),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "post-block" }, [
      _vm._m(1),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "post-block__list" },
        _vm._l(_vm.blogData, function (data, blogIndex) {
          return _c(
            "li",
            { key: blogIndex, staticClass: "post-block__list-item" },
            [
              _c("a", { staticClass: "u-hv-o", attrs: { href: data.link } }, [
                _c("p", { staticClass: "post-block__date u-ff-hel" }, [
                  _vm._v(_vm._s(data.date)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "post-block__ttl" }, [
                  _vm._v(_vm._s(data.title)),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "post-block__header" }, [
      _c("a", { attrs: { href: "/info/" } }, [_vm._v("更新情報")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "post-block__header" }, [
      _c("a", { attrs: { href: "/blog/" } }, [
        _vm._v("タイピングベースブログ"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }