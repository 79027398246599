import OBJ from '../../_obj';
import { Util } from '../../_util';
import { gsap } from "gsap";

const samplePage = () => {
  const obj = {

    init() {
    	const A = this;

			A.tab();
			A.tooltip();
			A.sampleAudioPlayer();


    },

		loading(){

		},


		setSelector(){
			this.$body = $('body');


		},

		getParam(name, url) {
			if (!url) url = window.location.href;
			name = name.replace(/[\[\]]/g, "\\$&");
			let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
					results = regex.exec(url);
			if (!results) return null;
			if (!results[2]) return '';
			return decodeURIComponent(results[2].replace(/\+/g, " "));
		},


		tab(){
			const A = this;
			const $nav = $('.tab-nav');
			const $btn = $('.tab-nav__btn');
			const $panelItem = $('.tab-panel-list__item');


			$btn.on('click', (e) => {
				const $this = $(e.currentTarget);
				const id = $this.data('id');
				const index = $this.index();

				$btn.removeClass('is-active');
				$nav.each((i,el) => {
					$(el).find('.tab-nav__btn').eq(index).addClass('is-active');
				});

				$panelItem.css('display', 'none');
				$panelItem.eq(index).fadeIn();

				// ankerScroll.ankerScroll('')
			});


			const prm = A.getParam('sample');


			if(prm){
				const index = $(`.${prm}`).index();
				$btn.eq(index).trigger('click');
			} else {
				$btn.eq(0).trigger('click');
			}
		},

		tooltip(){
			const $notClear = $('.js-not-clear');
			const $notClearTip = $('.js-not-clear-tooltip');



			$(window).on('mousemove', (e) => {
				$notClearTip.css({
					'top': e.clientY - 50,
					'left': e.clientX,
				})
			});


			$notClear
			.on('mouseover touchstart', (e) => {
				$notClearTip.addClass('is-active');
			})
			.on('mouseleave', () => {
				$notClearTip.removeClass('is-active');
			})

			$(window).on('scroll', () => {
				if(!$('body').hasClass('is-pc')){
					$notClearTip.removeClass('is-active');
				}
			});


		},

		sampleAudioPlayer(){
			const $player = $('#palyer-sample-data');
			const $playBtn = $player.find('.p-player__play');
			const $current = $player.find('.p-player__time__current');
			const $duration = $player.find('.p-player__time__duration');
			const $volumeController = $player.find('.p-player__v-controller');
			const $volume = $player.find('.p-player__v-controller__volume');

			let audio = new Audio();
			audio.src = '/assets/mp3/sample.mp3';

			audio.load();

			audio.addEventListener('loadedmetadata', (e) => {
				// console.log(audio.duration); // 総時間の取得
				let seconds = Math.floor(audio.duration);

				const ms = new Date(seconds * 1000).toISOString().slice(14, 19);

				// console.log(ms);

				$duration.text(ms);
			});

			$playBtn.on('click', (e) => {
				const $this = $(e.currentTarget);
				$this.toggleClass('is-play');

				if($this.hasClass('is-play')){
					audio.play();
				} else {
					audio.pause();
				}
			});

			$volumeController.on('click', (e) => {
				const $this = $(e.currentTarget);

				if($volume.hasClass('max')){
					$volume.removeClass('max');
					$volume.addClass('mid');

					audio.volume = 0.6;

				} else if($volume.hasClass('mid')){
					$volume.removeClass('mid');
					$volume.addClass('min');

					audio.volume = 0.2;

				} else if($volume.hasClass('min')){
					$volume.removeClass('min');
					$volume.addClass('mute');

					audio.muted = true;

				} else	if($volume.hasClass('mute')){
					$volume.removeClass('mute');
					$volume.addClass('max');

					audio.muted = false;
					audio.volume = 1;
				}


			});


			audio.addEventListener('timeupdate', (e) => {
				const current = Math.floor(audio.currentTime);
				const ms = new Date(current * 1000).toISOString().slice(14, 19);
				$current.text(ms);
			});


		},

		prmTabLink(_prm){

			const index = $(`.${_prm}`).index();
			$('.tab-nav__btn').eq(index);


		}







  }

  obj.init();
}


export {samplePage};