// 並びじゅん
// [レギュラープラン, ゆっくりプラン, 特急プラン, 翌日納品プラン, 当日納品プラン]
export default [
	{
		name: 'ケバ取り雰囲気再現型',
		price: [199,99,299,399,499]
	},
	{
		name: 'きれい仕上げ',
		price: [229,129,329,429,529]
	},
	{
		name: '記録型',
		price: [259,159,359]
	},
	{
		name: '素起こし',
		price: [259,159,359]
	}
]