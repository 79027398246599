import OBJ from '../../_obj';
import { Util } from '../../_util';
import { gsap } from "gsap";
import jqueryValidation from "jquery-validation";
import jqueryCookie from "jquery.cookie";


const contactPage = () => {
  const obj = {

    init() {
    	const A = this;

			$('input').on('keypress',(e) => {
				console.log(e);
        if(e.which == 13) {
            return false;
        }
    	});

			A.pageCloseAlert();
			A.formValidate();


    },

		loading(){

		},


		setSelector(){
			this.$body = $('body');


		},

		pageCloseAlert(){
			const message = '入力の途中ですが。ページを離れますか？';

			let submit_flg;
			let change_flg;

			$('input, select, textarea').on('change', () => {
				change_flg = true;
			});


			window.addEventListener('beforeunload', function(e) {
				if( !submit_flg  && change_flg) {
					e.preventDefault();
					e.returnValue = '他のページに移動しますか？';
				}
			});

			var form = document.getElementById('contactForm');
			form.addEventListener('submit', function(e) {
				submit_flg = true;
			});
		},


		formValidate(){
			const A = this


			$.validator.addMethod(
				"regex",
				function(value, element, regexp) {
					var check = false;
					return this.optional(element) || regexp.test(value);
				},
				// メッセージを省略
			);


			let validator = $('#contactForm').validate({
				rules: {
					corporate: {
						required: true,
					},
					name_1: {
						required: true,
					},
					name_2: {
						required: true,
					},
					read_1: {
						required: true,
						regex : /^[ぁ-んー]+$/,
					},
					read_2: {
						required: true,
						regex : /^[ぁ-んー]+$/,
					},
					mail_address: {
						required: true,
						email: true
					},
					mail_contents: {
						required: true,
					},
					concent: {
						required: true,
					},
					caution_concent: {
						required: true,
					},
				},
				messages: {
					corporate: {
						required: '※必須項目が入力されていません',
					},
					name_1: {
						required: '※必須項目が入力されていません',
					},
					name_2: {
						required: '※必須項目が入力されていません',
					},
					read_1: {
						required: '※必須項目が入力されていません',
						regex : '※ひらがなでご入力ください',
					},
					read_2: {
						required: '※必須項目が入力されていません',
						regex : '※ひらがなでご入力ください',
					},
					mail_contents: {
						required: '※必須項目が入力されていません',
					},
					mail_address: {
						required: '※必須項目が入力されていません',
						email: '※メールアドレスの形式が正しくありません'
					},
					concent: {
						required: '※同意されていません',
					},
					caution_concent: {
						required: '※同意されていません',
					},
				},
				errorPlacement: function(error, element){
					var errorKey = $(element).attr('id') + 'Error';
					$('#error_' + errorKey).remove();
					element.addClass('is-invalid');
					const errorP = $('<p>').text(error[0].innerText);
					const errorDiv = $('<div class="invalid-feedback" id="error_' + errorKey + '">').append(errorP);
					element.parent().append(errorDiv);
				},
				success: function(error, element) {
					var errorKey = $(element).attr('id') + 'Error';
					$('#error_' + errorKey).remove();
					$(error).remove();
					$(element).removeClass('is-invalid');
					$(element).removeClass('error');
				},

				invalidHandler(){

				}
			});

			// invalid-feedback

		},






  }

  obj.init();
}


export {contactPage};