var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { staticClass: "l-header" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "l-header__nav-block" }, [
      _c("div", { staticClass: "l-header__nav-block-inner" }, [
        _c(
          "ul",
          { staticClass: "l-header__main-nav" },
          [
            _vm._l(_vm.navData.mainNav, function (data) {
              return [
                data.dorpDown
                  ? _c("li", { key: data.id, staticClass: "dropDown-parent" }, [
                      _c("a", { attrs: { href: data.url } }, [
                        _vm._v(_vm._s(data.txt) + " "),
                        _c("span", { staticClass: "toggle-icn SP" }),
                      ]),
                      _vm._v(" "),
                      data.dorpDown
                        ? _c(
                            "ul",
                            { staticClass: "dropDown-list" },
                            _vm._l(data.dorpDown, function (data2) {
                              return _c("li", { key: data2.id }, [
                                _c("a", { attrs: { href: data2.url } }, [
                                  _vm._v(_vm._s(data2.txt)),
                                ]),
                              ])
                            }),
                            0
                          )
                        : _vm._e(),
                    ])
                  : data.class
                  ? _c("li", { key: data.id }, [
                      _c(
                        "a",
                        { class: data.class, attrs: { href: data.url } },
                        [_vm._v(_vm._s(data.txt))]
                      ),
                    ])
                  : _c("li", { key: data.id }, [
                      _c("a", { attrs: { href: data.url } }, [
                        _vm._v(_vm._s(data.txt)),
                      ]),
                    ]),
              ]
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "l-header__contact-nav" },
          [
            _vm._m(1),
            _vm._v(" "),
            _vm._l(_vm.navData.contactNav, function (data) {
              return _c("li", { key: data.id }, [
                _c("a", { attrs: { href: data.url } }, [
                  _vm._v(_vm._s(data.txt)),
                ]),
              ])
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "l-header__other-nav SP" },
          _vm._l(_vm.navData.footerNav2, function (data) {
            return _c("li", { key: data.id }, [
              _c("a", { attrs: { href: data.url } }, [
                _vm._v(_vm._s(data.txt)),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "l-header__hum-btn SP", on: { click: _vm.navOp } },
      [_c("span"), _vm._v(" "), _c("span"), _vm._v(" "), _c("span")]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "l-header__logo" }, [
      _c("a", { attrs: { href: "/" } }, [
        _c("img", {
          attrs: {
            src: "/assets/img/common/logo_typingbase.svg",
            alt: "タイピングベース",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "quote PC" }, [
      _c(
        "a",
        {
          staticClass: "js-quote-btn non-scroll u-hv-o",
          attrs: { href: "#modal-quote" },
        },
        [
          _c("img", {
            attrs: {
              src: "/assets/img/common/icn-quote.svg",
              alt: "簡単見積もり",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "quote SP" }, [
      _c(
        "a",
        {
          staticClass: "js-quote-btn non-scroll u-hv-o",
          attrs: { href: "#modal-quote" },
        },
        [
          _c("img", {
            attrs: {
              src: "/assets/img/common/icn-quote.svg",
              alt: "簡単見積もり",
            },
          }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }