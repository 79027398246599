import OBJ from '../../_obj';
import { Util } from '../../_util';
import { gsap } from "gsap";

const pricePage = () => {
  const obj = {

    init() {
    	const A = this;

			A.priceLabelSpSet();
			A.tabNavClone();
			A.tab();

    },

		loading(){

		},


		setSelector(){
			this.$body = $('body');
		},

		getParam(name, url) {
			if (!url) url = window.location.href;
			name = name.replace(/[\[\]]/g, "\\$&");
			let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
					results = regex.exec(url);
			if (!results) return null;
			if (!results[2]) return '';
			return decodeURIComponent(results[2].replace(/\+/g, " "));
		},


		tab(){
			const A = this;
			const $nav = $('.tab-nav');
			const $btn = $('.tab-nav__btn');
			const $content = $('.price-content-block');

			const ankerScroll = new Util();

			$btn.on('click', (e) => {
				const $this = $(e.currentTarget);
				const id = $this.data('id');
				const index = $this.index();

				$btn.removeClass('is-active');
				$nav.each((i,el) => {
					$(el).find('.tab-nav__btn').eq(index).addClass('is-active');
				});

				$content.css('display', 'none');
				$content.filter('.' + id).fadeIn();

				ankerScroll.ankerScroll('')
			});


			const prm = A.getParam('price');

			if(prm){
				const index = $(`.${prm}`).index();
				$btn.eq(index).trigger('click');
			} else {
				$btn.eq(0).trigger('click');
			}

		},

		tabNavClone(){
			const $nav = $('.tab-nav');
			const btnWrapCode = '<div class="tab-nav-fixed-wrap"></div>';

			$('.main-content').append(btnWrapCode);
			$('.tab-nav-fixed-wrap').append($nav.clone());

			$(window).on('scroll', (e) => {
				const $this = $(e.currentTarget);
				const val = $this.scrollTop();
				const footerOffset = $('.l-footer').offset().top;
				const wH = $this.height();

				if(100 < val){
					$('.tab-nav-fixed-wrap .tab-nav').addClass('is-active');
				} else {
					$('.tab-nav-fixed-wrap .tab-nav').removeClass('is-active');
				}

				if(footerOffset - wH < val){
					$('.tab-nav-fixed-wrap .tab-nav').addClass('is-footer-hidden');
				} else {
					$('.tab-nav-fixed-wrap .tab-nav').removeClass('is-footer-hidden');
				}
			})
		},


		priceLabelSpSet(){
			const priceHeadHtml = $('.price-table__head-label-block').html();
			const $priceTableRow = $('.price-table__row');
			const wrapCode = '<div class="price-table__row-label SP"></div>';

			$priceTableRow.each((i,el) => {
				$(el).find('.price-table__price-block').before(wrapCode);
				$(el).find('.price-table__row-label').append(priceHeadHtml);
			});
		}





  }

  obj.init();
}


export {pricePage};