import OBJ from './_obj';
import { gsap } from 'gsap';

class nav {
  constructor(){
		const A = this;

		this.loadAnker();
		if($('body').hasClass('top-page')){
			this.ankerSet();
		}


  }




  ankerSet(){
    const $btnAnker = $('.l-header__main-nav .to-anker');
    const $btnAnkerFooter = $('.l-footer__nav--1 .to-anker');

    $btnAnker.each((i,el) => {
      let $this = $(el);
      $this.attr('href', $this.attr('href').split('/')[1]);
    });
		$btnAnkerFooter.each((i,el) => {
      let $this = $(el);
      $this.attr('href', $this.attr('href').split('/')[1]);
    });

  }


  loadAnker(){
    const hash = location.hash;
    const url = location.pathname;

    if(!hash || !$(hash).length) return;

    let speed = .2;
    let hH = $('.l-header').outerHeight();
    let position = $(hash).offset().top - hH;
    gsap.to($('html, body'), { scrollTop: position, ease: "power1.in", duration: speed});

    history.replaceState('','',url);

  }

}

export {nav};