<template>
  <header class="l-header">
		<h1 class="l-header__logo"><a href="/"><img src="/assets/img/common/logo_typingbase.svg" alt="タイピングベース"></a></h1>

		<div class="l-header__nav-block">
			<div class="l-header__nav-block-inner">
				<ul class="l-header__main-nav">
					<template v-for="data in navData.mainNav">
						<li v-if="data.dorpDown" :key="data.id" class="dropDown-parent">
							<a :href="data.url">{{data.txt}} <span class="toggle-icn SP"></span></a>
							<ul v-if="data.dorpDown" class="dropDown-list">
								<li v-for="data2 in data.dorpDown" :key="data2.id">
									<a :href="data2.url">{{data2.txt}}</a>
								</li>
							</ul>
						</li>
						<li v-else-if="data.class" :key="data.id">
							<a :class="data.class" :href="data.url">{{data.txt}}</a>
						</li>
						<li v-else :key="data.id">
							<a :href="data.url">{{data.txt}}</a>
						</li>
					</template>
				</ul>

				<ul class="l-header__contact-nav">
					<li class="quote PC"><a class="js-quote-btn non-scroll u-hv-o" href="#modal-quote"><img src="/assets/img/common/icn-quote.svg" alt="簡単見積もり"></a></li>
					<li v-for="data in navData.contactNav" :key="data.id">
						<a :href="data.url">{{data.txt}}</a>
					</li>
				</ul>

				<ul class="l-header__other-nav SP">
					<li v-for="data in navData.footerNav2" :key="data.id">
						<a :href="data.url">{{data.txt}}</a>
					</li>
				</ul>
			</div>
		</div>

		<p class="quote SP"><a class="js-quote-btn non-scroll u-hv-o" href="#modal-quote"><img src="/assets/img/common/icn-quote.svg" alt="簡単見積もり"></a></p>

		<div class="l-header__hum-btn SP" @click="navOp">
			<span></span>
			<span></span>
			<span></span>
		</div>
	</header>
</template>


<script>
import gsap from 'gsap';
import navData from './navData'
import OBJ from '../../_obj'

export default {
	props: ['scrollTop', 'open'],

  data: () => {
    return {
      message: 'header',
			navData: [],
    }
  },
	created(){
		this.navData = navData;
	},

	mounted(){
		this.headerScrollSet();

		const resizeEvent = new Event('resize');
		const $body = document.querySelector('body');

		window.addEventListener('resize', () => {
			if(window.innerWidth <= OBJ.BP){
				this.spToggleNav();
				console.log('リサイズ')
			}
		});

		window.dispatchEvent(resizeEvent);

		window.addEventListener('load', () => {
			$body.classList.add('is-loaded');
		});
	},

	methods: {
		navOp(){
			this.$emit('parent-event');
		},

		headerScrollSet(){
			const headerEl = document.querySelector('.l-header');
			window.addEventListener('scroll', () => {
				const scrVal = window.pageYOffset;

				if(50 < scrVal){
					headerEl.classList.add('is-min');
				} else {
					headerEl.classList.remove('is-min');
				}
			});
		},

		spToggleNav(){
			const $toggleBtn = document.querySelectorAll('.dropDown-parent > a');
			let opFlag = false;
			let dur = 0.5;

			$toggleBtn.forEach((target) => {
				target.addEventListener('click', (e) => {
					e.preventDefault();
					console.log('クリック');

					if(opFlag){
						// 閉じる
						target.classList.remove('is-open');
						gsap.to(target.nextElementSibling, {
							height: 0,
							duration: dur,
							ease: "power2.inOut",
							onComplete(){
								opFlag = false;
							}
						});
					} else {
						// 開く
						target.classList.add('is-open');
						gsap.to(target.nextElementSibling, {
							height:'auto',
							duration: dur,
							ease: "power2.inOut",
							onComplete(){
								opFlag = true;
							}
						});
					}
				})
			});
		}
	}
};
</script>


<style scoped lang="scss">
@use "../../../scss/variable/define" as *;
@use "../../../scss/mixin/mixin" as *;
@use "sass:math";
.l-header{
	width: 100%;
	min-width: 970px;
	height: 135px;
	padding: 10px 30px 0;
	background-color: #fff;
	border-bottom: 5px solid $c_orange_1;
	@include flex($align: center);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	@media only screen and (max-width: $breakpoint){
		min-width: auto;
		height: vw(60);
		padding: vw(3) vw(20) 0;
		border-width: vw(3);
	}


	&__logo{
		width: 210px;
		@media only screen and (max-width: $breakpoint){
			width: vw(90);
		}
		a{
			display: block;
		}
	}

	.quote.SP{
		width: vw(60);
		position: absolute;
		right: vw(80);
	}

	&__nav-block{
		@media only screen and (min-width: $breakpoint + 1){
			height: 85px;
			@include flex($align: flex-end);
			position: relative;
		}
		@media only screen and (max-width: $breakpoint){
			width: vw(320);
			height: calc(100% - vw(60));
			background-color: #fff;
			position: fixed;
			top: vw(60);
			right: 0;
			overflow: scroll;
		}
	}

	&__main-nav{
		@media only screen and (min-width: $breakpoint + 1){
			@include flex();
		}
		> li{
			position: relative;
			@media only screen and (max-width: $breakpoint){
				width: 100%;
				// height: vw(50);
			}
			&:nth-child(n+2){
				@media only screen and (min-width: $breakpoint + 1){
					margin-left: 30px;
				}
				@media only screen and (max-width: $breakpoint){
					border-top: 1px solid rgba($c_orange_1, 0.5);
				}
			}
			> a{
				font-size: 14px;
				font-weight: 700;
				color: $c_orange_1;
				@media only screen and (max-width: $breakpoint){
					width: 100%;
					height: vw(50);
					padding: 0 vw(20);
					font-size: vw(14);
					@include flex($align: center);
				}

				&.is-open{
					.toggle-icn{
						transform: rotate(135deg);
					}
				}

				.toggle-icn{
					width: vw(12);
					height: vw(12);
					display: block;
					position: absolute;
					top: 0;
					bottom: 0;
					right: vw(10);
					margin: auto;

					transition: all .3s;

					&::before,&::after{
						content: '';
						display: block;
						width: 100%;
						height: 100%;
						@include posiCenter;
						background-color: $c_orange_1;
					}
					&::before{
						width: vw(2);
					}
					&::after{
						height: vw(2);
					}
				}
			}
		}

		.dropDown-list{
			@media only screen and (min-width: $breakpoint + 1){
				width: 157px;
				top: 23px;
				position: absolute;
				border: 1px solid $c_orange_1;
			}
			@media only screen and (max-width: $breakpoint){
				width: 100%;
				height: 0;
				overflow: hidden;
			}
			li{
				@media only screen and (max-width: $breakpoint){
					border-top: 1px solid rgba($c_orange_1, 0.5);
				}
				&:nth-child(n+2){
					border-top: 1px solid $c_orange_1;
					@media only screen and (max-width: $breakpoint){
						border-color: rgba($c_orange_1, 0.5);
					}
				}
				a{
					width: 100%;
					// height: 30px;
					padding: 10px 8px;
					font-size: 12px;
					font-weight: 700;
					color: $c_orange_1;
					background-color: #fff;
					@include flex($align: center);
					@media only screen and (max-width: $breakpoint){
						padding: vw(10) vw(10) vw(10) vw(40);
						font-size: vw(13);
					}
				}
			}
		}
	}

	&__contact-nav{
		@media only screen and (min-width: $breakpoint + 1){
			@include flex();
			position: absolute;
			top: 0;
			right: 0;
		}
		@media only screen and (max-width: $breakpoint){
			padding: vw(20) vw(20);
			border-top: 1px solid rgba($c_orange_1, 0.5);
			border-bottom: 1px solid rgba($c_orange_1, 0.5);
		}
		li:not(.quote){
			width: 230px;
			height: 40px;
			@media only screen and (max-width: $breakpoint){
				width: vw(250);
				height: vw(50);
				margin: 0 auto;
			}
			&:nth-child(n+2){
				@media only screen and (min-width: $breakpoint + 1){
					margin-left: 14px;
				}
				@media only screen and (max-width: $breakpoint){
					margin-top: vw(15);
				}
			}
			&:nth-child(2){
				@media only screen and (max-width: $breakpoint){
					margin-top: 0;
				}
			}
			a{
				width: 100%;
				height: 100%;
				@include flex(center,center);
				border: 2px solid $c_orange_1;
				color: $c_orange_1;
				font-size: 13px;
				font-weight: 700;
				@media only screen and (max-width: $breakpoint){
					font-size: vw(15);
				}
			}
		}
		.quote{
			width: 100px;
			margin-top: -8px;
			margin-right: 20px;
		}
	}

	&__other-nav{
		@include flex();
		li{
			width: 50%;
			height: vw(30);
			border-bottom: 1px solid rgba($c_orange_1, 0.5);
			&:nth-child(odd){
				border-right: 1px solid rgba($c_orange_1, 0.5);
			}
			a{
				width: 100%;
				height: 100%;
				padding: 0 vw(10);
				font-size: vw(11);
				letter-spacing: 0;
				color: $c_orange_1;
				@include flex($align: center);
			}
		}
	}

	&__hum-btn{
		width: vw(30);
		height: vw(20);
		position: absolute;
		// top: vw(32);
		right: vw(24);
		top: 0;
		bottom: 0;
		margin: auto;
		cursor: pointer;
		z-index: 102;
		span{
			width: 100%;
			height: 2px;
			background-color: $c_orange_1;
			display: block;
			position: absolute;
			left: 0;
			&:nth-child(1){
				top: 0;
			}
			&:nth-child(2){
				top: 0;
				bottom: 0;
				margin: auto;
			}
			&:nth-child(3){
				bottom: 0;
			}
		}
	}



}
////////////////////////////////////////////////////////////
// animation
////////////////////////////////////////////////////////////
$dur: .4s;
.l-header{
	transition: $dur;

	&__logo{
		transition: $dur;
	}
	&__nav-block{
		transition: $dur;
	}
	&__contact-nav{
		li{
			transition: $dur;
			a{
				transition: $dur;
			}
		}
	}
	&.is-min{
		@media only screen and (min-width: $breakpoint + 1){
			height: 100px;
			padding-top: 3px;
		}
		.l-header{
			&__logo{
				@media only screen and (min-width: $breakpoint + 1){
					width: 160px;
				}
			}
			&__nav-block{
				@media only screen and (min-width: $breakpoint + 1){
					height: 70px;
				}
			}
			&__contact-nav{
				li:not(.quote){
					@media only screen and (min-width: $breakpoint + 1){
						width: 180px;
						height: 32px;
					}
					a{
						@media only screen and (min-width: $breakpoint + 1){
							font-size: 13px;
						}
					}
				}
				.quote{
					width: 80px;
					margin-right: 10px;
					margin-top: -7px;
				}
			}
		}
	}
}


</style>