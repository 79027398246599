import OBJ from '../../_obj';
import FORM_OBJ from './_formObj';
import PRICE from '../../_priceData';
import PLAN from '../../_planData';
import OPTION from '../../_optionData';
import SETTION from './_session';
import { Util } from '../../_util';
import { gsap } from "gsap";
import jqueryValidation from "jquery-validation";
import jqueryCookie from "jquery.cookie";
import datepickerFactory from 'jquery-datepicker';
import datepickerJAFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-ja';
import 'jquery-ui/themes/base/all.css';


const orderPage = () => {
  const obj = {

    init() {
    	const A = this;

			$('input').on('keypress',(e) => {
        if(e.which == 13) {
            return false;
        }
    	});

			window.onpageshow = function(){
				document.getElementById('orderForm').reset();
				// const sessionStorageCopy  = sessionStorage;

				// A.pageCloseAlert();
				A.formValidate();

				A.selectNumSet();
				A.loadSelectInitial();
				A.initialSetting();

				A.overlaySet();

				A.formStep();

				$('input, select').on('change', (e) => {
					$(e.currentTarget).parents('.p-select').addClass('selected');
					SETTION.post($(e.currentTarget));
					A.quoteSet();
				});
				$('textarea').on('change', (e) => {
					SETTION.post($(e.currentTarget));
				});

				SETTION.init();

				setTimeout(() => {
					A.step1Control();
					A.step2Control();
					A.step3Control();
					A.step4Control();
					A.step5Control();
					A.step6Control();
				},500);

			};



			$.get("https://holidays-jp.github.io/api/v1/date.json", (holidaysData) => {
				A.datapicker(holidaysData);
			});



			// sp合計金額のやつ
			$('.sp-pulltab').on('click', () => {
				$('.quote-block').toggleClass('is-view');
			});

			if(OBJ.BP < $(window).width()){
				A.quoteFixed();
			}

			// STEP1 //////////////////////////////////////////////////////////////////////////////////////////
			$('input[name="inputSelect"]').on('change', (e) => {
				FORM_OBJ.inputSelect =  $('input[name="inputSelect"]:checked').val();
				if($(e.currentTarget).val() == 'true'){
					A.dataBaseFormInitial();
					A.omitAlertHidden();
					// console.log(FORM_OBJ.fileNumber)
					if(FORM_OBJ.fileNumber == 1){
						A.data1FormInitial();
						A.data1SelectInitial();
						$('#formTalkNumber2').addClass('u-hidden');
					} else if(FORM_OBJ.fileNumber == 2){
						A.data2FormInitial();
						A.data2SelectInitial();
						$('#formTalkNumber2').removeClass('u-hidden');
					}
				} else {
					A.dataBaseFormHidden();
					A.dataFormHidden();
					A.omitAlertInitial();
					// 音声データ数をリセットする
					$('#fileNumber').val('').trigger('change').parents('.set-placeholder').removeClass('selected');
					A.setNonSelect('fileNumber');
					FORM_OBJ.fileNumber = null;
					A.data1SelectInitial();
				}
			});
			$('#fileNumber').on('change', (e) => {
				if(parseInt($(e.currentTarget).val()) == 1){
					A.data1FormInitial();
					A.data1SelectInitial();
					$('#formTalkNumber2').addClass('u-hidden');
				} else if(parseInt($(e.currentTarget).val()) == 2){
					A.data2FormInitial();
					A.data2SelectInitial();
					$('#formTalkNumber2').removeClass('u-hidden');
				} else if(parseInt($(e.currentTarget).val()) >= 3){
					A.overDataFormInitial();
				}
			});

			$('#speakerNumber1, #speakerNumber2').on('change', (e) => {
				FORM_OBJ.speakerNumber1 = $(e.currentTarget).val();
				A.formTalkNumberInitial_wasyaNum();
			});
			// STEP2 //////////////////////////////////////////////////////////////////////////////////////////
			A.fileNameBind();



			// STEP3 //////////////////////////////////////////////////////////////////////////////////////////
			$('#talkNumber1').on('change', () => {
				A.formTalkNumber1Initial();
			});
			$('#talkNumber2').on('change', () => {
				A.formTalkNumber2Initial();
			});
			$('#timeStamp').on('change', () => {
				A.formTimeStampInitial();
			});


			$.get("https://holidays-jp.github.io/api/v1/date.json", (holidaysData) => {
				$('#plan').on('click', () => {
					FORM_OBJ.format1Before = $('#format1').val();
					FORM_OBJ.format2Before = $('#format2').val();

					FORM_OBJ.talkNumber1Before = $('#talkNumber1').val();
					FORM_OBJ.talkNumber2Before = $('#talkNumber2').val();
				});
				$('#plan').on('change', () => {
					A.planValidation(holidaysData);
				});
			});

    },

		loading(){

		},


		setSelector(){
			this.$body = $('body');


		},

		pageCloseAlert(){
			const message = '入力の途中ですが。ページを離れますか？';

			// window.addEventListener('beforeunload', (e) => {
			// 	e.preventDefault()
			// 	e.returnValue = message
			// 	return message
			// });


			// history.pushState(null, null, location.href);
			// window.addEventListener('popstate', (e) => {
			// 	alert(message);
			// 	// history.go(1);
			// });

			let submit_flg;
			let change_flg;

			$('input, select, textarea').on('change', () => {
				change_flg = true;
			});


			window.addEventListener('beforeunload', function(e) {
				if( !submit_flg  && change_flg) {
					e.preventDefault();
					e.returnValue = '他のページに移動しますか？';
				}
			});

			var form = document.getElementById('orderForm');
			form.addEventListener('submit', function(e) {
				submit_flg = true;
			});
		},

		overlaySet(){
			$('body').append('<div class="datepicker-overlay"></div>');
		},

		dayOfWeekGet(_date, _holidaysData){
			const A = this;
			const day = new Date(_date);
			let holidayFlag = false;

			if(day.getDay() === 0 || day.getDay() === 6){
				holidayFlag = true;
			}

			const holidays = Object.keys(_holidaysData);
			for (let i = 0; i < holidays.length; i++) {
				const holiday = new Date(Date.parse(holidays[i]));
				if (holiday.getYear() == day.getYear() &&
						holiday.getMonth() == day.getMonth() &&
						holiday.getDate() == day.getDate()) {
						holidayFlag = true;
				}
			}

			FORM_OBJ.selectDateHoliday = holidayFlag

			// console.log('土日祝かどうか',FORM_OBJ.selectDateHoliday);

		},

		datapicker(_holidaysData){

			const A = this;
			datepickerFactory($);
			datepickerJAFactory($);

			const dt = new Date();
			const y = dt.getFullYear();
			const m = ("00" + (dt.getMonth()+1)).slice(-2);
			const d = ("00" + dt.getDate()).slice(-2);
			const hour = dt.getHours();
			const minute = dt.getMinutes();
			const second = dt.getSeconds();
			const result = y + "/" + m + "/" + d;

			let minDate = 0;

			// if(12 <= hour){
			// 	minDate = 1;
			// 	FORM_OBJ.todayDelivery = false;
			// 	$('#plan option[value="4"]').prop('disabled', true);
			// }

			// if(15 <= hour){
			// 	minDate = 2;
			// 	FORM_OBJ.tomorrowDelivery = false;
			// 	$('#plan option[value="3"]').prop('disabled', true);
			// }

			// day-sunday day-saturday day-holiday
			// day-weekday

			$('.datepicker').datepicker({
				dateFormat: 'yy/mm/dd',
				numberOfMonths: 1,
				minDate: minDate,
				beforeShow(){
					$('.datepicker-overlay').addClass('is-active');
				},
				onClose() {
					$('.datepicker-overlay').removeClass('is-active');

					FORM_OBJ.preferredDate = $('#preferredDate').val();

					A.dayOfWeekGet(FORM_OBJ.preferredDate, _holidaysData);

				},
				onSelect(dateText) {
					// console.log(result);
					const day1 = new Date($('.datepicker').val());
					const day2 = new Date(result);
					const termDay = (day1 - day2) / 86400000;


					FORM_OBJ.daysLater = termDay;

					A.planSet();

					$('.datepicker').trigger('change');


				},

				beforeShowDay(date){
					if (date.getDay() == 0) {
						return [true, 'day-sunday', null];
					} else if (date.getDay() == 6) {
						return [true, 'day-saturday', null];
					}

					const holidays = Object.keys(_holidaysData);
					for (let i = 0; i < holidays.length; i++) {
						const holiday = new Date(Date.parse(holidays[i]));
						if (holiday.getYear() == date.getYear() &&
								holiday.getMonth() == date.getMonth() &&
								holiday.getDate() == date.getDate()) {
								return [true, 'day-holiday', null];
						}
					}

					return [true, 'day-weekday', null];
				}

			});


			// console.log(_holidaysData);




			$.datepicker.regional['ja'] = {
				closeText: '閉じる',
				prevText: '<前',
				nextText: '次>',
				currentText: '今日',
				monthNames: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
				monthNamesShort: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
				dayNames: ['日曜日','月曜日','火曜日','水曜日','木曜日','金曜日','土曜日'],
				dayNamesShort: ['日','月','火','水','木','金','土'],
				dayNamesMin: ['日','月','火','水','木','金','土'],
				weekHeader: '週',
				dateFormat: 'yy/mm/dd',
				firstDay: 0,
				isRTL: false,
				showMonthAfterYear: true,
				yearSuffix: '年'
			};
			$.datepicker.setDefaults($.datepicker.regional['ja']);

		},


		planSet(){
			const A = this;

			// $('#plan option').prop('disabled', true);

			// if(FORM_OBJ.daysLater <= 1){
			// 	console.log('翌日希望');
			// 	$('#plan option[value="3"]').prop('disabled', false);
			// 	$('#plan option[value="4"]').prop('disabled', false);
			// }

			// if(FORM_OBJ.daysLater <= 0){
			// 	console.log('当日希望');
			// 	$('#plan option[value="4"]').prop('disabled', false);
			// } else if(FORM_OBJ.daysLater <= 1){
			// 	console.log('翌日希望');
			// 	$('#plan option[value="3"]').prop('disabled', false);
			// 	$('#plan option[value="4"]').prop('disabled', false);
			// }

			// if( 2 <= FORM_OBJ.daysLater){
			// 	console.log('とりあえず全部選択できるよ');
			// 	$('#plan option').prop('disabled', false);
			// }

			// if(!FORM_OBJ.todayDelivery){
			// 	$('#plan option[value="4"]').prop('disabled', true);
			// }
			// if(!FORM_OBJ.tomorrowDelivery){
			// 	$('#plan option[value="3"]').prop('disabled', true);
			// }

		},



		quoteFixed(){
			const $target = $('.quote-block');
			const $triggerEl = $('.form-block');

			$(window).on('scroll', (e) => {
				const wH = $(e.currentTarget).height();
				const val = $(e.currentTarget).scrollTop();
				const offset = $triggerEl.offset().top;
				const mT = parseInt($triggerEl.css('margin-top'));
				const hH = $('.l-header.is-min').outerHeight();
				const triggerH = $triggerEl.height();

				const posi = Math.floor(offset - hH - mT)

				// console.log($target.outerHeight(), wH);

				// console.log(val, offset, hH, mT);
				// console.log(val, posi);

				if(posi < val){
					$target.addClass('is-fixed');
				} else {
					$target.removeClass('is-fixed');
				}

				if((offset + triggerH) - (wH) + (wH - $target.outerHeight() - 150) < val){
					$target.addClass('is-absolute');
				} else {
					$target.removeClass('is-absolute');
				}
			}).trigger('scroll');


		},

		timeSet(){
			const A = this;
			const $time = $('.modal-quote__preview-item--time .time');

			// データ1開始時間
			FORM_OBJ.ttime1_s_h = parseInt($('select[name=ttime1_s_h]').val());
			FORM_OBJ.ttime1_s_m = parseInt($('select[name=ttime1_s_m]').val());
			FORM_OBJ.ttime1_s_s = parseInt($('select[name=ttime1_s_s]').val());
			FORM_OBJ.ttime1_s_SET_M = (FORM_OBJ.ttime1_s_h * 60)  + FORM_OBJ.ttime1_s_m + (FORM_OBJ.ttime1_s_s ? 1 : 0);

			// データ1終了時間
			FORM_OBJ.ttime1_e_h = parseInt($('select[name=ttime1_e_h]').val());
			FORM_OBJ.ttime1_e_m = parseInt($('select[name=ttime1_e_m]').val());
			FORM_OBJ.ttime1_e_s = parseInt($('select[name=ttime1_e_s]').val());
			FORM_OBJ.ttime1_e_SET_M = (FORM_OBJ.ttime1_e_h * 60)  + FORM_OBJ.ttime1_e_m + (FORM_OBJ.ttime1_e_s ? 1 : 0);

			// データ1開始時間
			FORM_OBJ.ttime2_s_h = parseInt($('select[name=ttime2_s_h]').val());
			FORM_OBJ.ttime2_s_m = parseInt($('select[name=ttime2_s_m]').val());
			FORM_OBJ.ttime2_s_s = parseInt($('select[name=ttime2_s_s]').val());
			FORM_OBJ.ttime2_s_SET_M = (FORM_OBJ.ttime2_s_h * 60)  + FORM_OBJ.ttime2_s_m + (FORM_OBJ.ttime2_s_s ? 1 : 0);

			// データ1終了時間
			FORM_OBJ.ttime2_e_h = parseInt($('select[name=ttime2_e_h]').val());
			FORM_OBJ.ttime2_e_m = parseInt($('select[name=ttime2_e_m]').val());
			FORM_OBJ.ttime2_e_s = parseInt($('select[name=ttime2_e_s]').val());
			FORM_OBJ.ttime2_e_SET_M = (FORM_OBJ.ttime2_e_h * 60)  + FORM_OBJ.ttime2_e_m + (FORM_OBJ.ttime2_e_s ? 1 : 0);

			// console.log(FORM_OBJ.ttime1_s_h,FORM_OBJ.ttime1_s_m,FORM_OBJ.ttime1_s_s);
			// console.log('データ1：',FORM_OBJ.ttime1_s_SET_M,FORM_OBJ.ttime1_e_SET_M)
			// console.log('データ2：',FORM_OBJ.ttime2_s_SET_M,FORM_OBJ.ttime2_e_SET_M)

			// データ１ トータル
			FORM_OBJ.ttime1_Total = FORM_OBJ.ttime1_e_SET_M - FORM_OBJ.ttime1_s_SET_M;
			// データ2 トータル
			FORM_OBJ.ttime2_Total = FORM_OBJ.ttime2_e_SET_M - FORM_OBJ.ttime2_s_SET_M;
			// 両データ トータル
			FORM_OBJ.totalTime = FORM_OBJ.ttime1_Total + FORM_OBJ.ttime2_Total;

			if(FORM_OBJ.totalTime < 1){
				FORM_OBJ.ttime1_Total = 0;
				FORM_OBJ.ttime2_Total = 0;
			}


			if( 3 <= parseInt($('#fileNumber').val()) || $('input[name="inputSelect"]:checked').val() == 'false' || FORM_OBJ.totalTime < 1){
				$('.quote-time-block__total-time .time').text('0');
				$('.quote-time-block__1-time .time').text('0');
				$('.quote-time-block__2-time .time').text('0');
				$('#totalTime').val('0');
			} else if(parseInt($('#fileNumber').val()) == 1 && FORM_OBJ.ttime1_Total > 0){
				$('.quote-time-block__total-time .time').text(FORM_OBJ.ttime1_Total);
				$('.quote-time-block__1-time .time').text(FORM_OBJ.ttime1_Total);
				$('.quote-time-block__2-time .time').text('0');
				$('#totalTime').val(FORM_OBJ.ttime1_Total);
				// console.log(FORM_OBJ.ttime1_Total);
			} else if(parseInt($('#fileNumber').val()) == 2 && FORM_OBJ.totalTime > 0){
				$('.quote-time-block__total-time .time').text(FORM_OBJ.totalTime);
				$('.quote-time-block__1-time .time').text(FORM_OBJ.ttime1_Total);
				$('.quote-time-block__2-time .time').text(FORM_OBJ.ttime2_Total);
				$('#totalTime').val(FORM_OBJ.totalTime);
				// console.log(FORM_OBJ.totalTime);
			}

			// console.log('総時間：',FORM_OBJ.totalTime, 'データ1：',FORM_OBJ.ttime1_Total, 'データ2：',FORM_OBJ.ttime2_Total);

			// console.log('データ1：',FORM_OBJ.ttime1_Total)
			// console.log('データ2：',FORM_OBJ.ttime2_Total)


			A.priceOrderSet();
		},

		priceOrderSet(){
			const A = this;

			FORM_OBJ.speakerNumber1 = $('select[name=speakerNumber1]').val() ? $('select[name=speakerNumber1]').val() : null;
			FORM_OBJ.speakerNumber2 = $('select[name=speakerNumber2]').val() ? $('select[name=speakerNumber2]').val() : null;

			FORM_OBJ.plan = parseInt($('select[name=plan]').val());
			FORM_OBJ.format1 = parseInt($('select[name=format1]').val());
			FORM_OBJ.format2 = parseInt($('select[name=format2]').val());

			FORM_OBJ.talkNumber1 = parseInt($('select[name=talkNumber1]').val());
			FORM_OBJ.talkNumber2 = parseInt($('select[name=talkNumber2]').val());

			FORM_OBJ.expertise = $('select[name=expertise]').val();

			FORM_OBJ.timeStamp = $('select[name=timeStamp]').val();
			FORM_OBJ.timeStampSrt = $('select[name=timeStampSrt]').val();

			FORM_OBJ.selfRole = $('select[name=selfRole]').val();
			FORM_OBJ.webData = $('select[name=webData]').val();
			FORM_OBJ.holidayDelivery = $('select[name=holidayDelivery]').val();
			FORM_OBJ.returnData = $('select[name=returnData]').val();


			let postalMailArray = [];

			postalMailArray.push($('select[name=seikyu]').val());
			postalMailArray.push($('select[name=mitsumori]').val());
			postalMailArray.push($('select[name=nohin]').val());

			FORM_OBJ.document = false;

			postalMailArray.forEach((el, i) => {
				if(el == '郵送'){
					FORM_OBJ.document = true;
					return
				}
			});


			A.quoteCalc();
		},

		quoteCalc(){
			const A = this;

			let data1_price = 0;
			let data2_price = 0;
			let total_price = 0;
			let total_price_taxin = 0;

			let holidayDelivery = 0;
			let returnData = 0;
			let document = 0;

			if(FORM_OBJ.holidayDelivery == 'あり'){
				holidayDelivery = OPTION.holidayDelivery;
			}
			if(FORM_OBJ.returnData == 'あり'){
				returnData = OPTION.returnData;
			}
			if(FORM_OBJ.document){
				document = OPTION.document;
			}

			// console.log('ちぇーーーーーんじ')

			// console.log();


			//データ1の計算//////////////////////////////////////////////////////////////////////////////////////////////////////
			const data1Calc = () => {
				if(isNaN(FORM_OBJ.plan) || isNaN(FORM_OBJ.format1) || !FORM_OBJ.speakerNumber1) return;


				let data1_base_price = PRICE[FORM_OBJ.format1].price[FORM_OBJ.plan];
				let data1_talkNumber = OPTION.wasya[FORM_OBJ.talkNumber1];

				let data1_wasyaNum = 0;
				let data1_expertise = 0;
				let data1_timeStamp = 0;
				let data1_timeStampSrt = 0;
				let data1_selfRole = 0;

				let data1_webData = 0;

				// console.log('ベース料金：' + data1_base_price);
				// console.log('話者人数オプション：' + data1_wasya_price);


				if(FORM_OBJ.speakerNumber1 == '6人以上'){
					data1_wasyaNum = OPTION.wasyaNum;
				}

				if(FORM_OBJ.expertise == 'あり' || FORM_OBJ.expertise == 'あり-＜1＞のみ' || FORM_OBJ.expertise == 'あり-両方'){
					data1_expertise = OPTION.expertise;
				}
				if(FORM_OBJ.timeStamp == 'あり' || FORM_OBJ.timeStamp == 'あり-＜1＞のみ' || FORM_OBJ.timeStamp == 'あり-両方'){
					data1_timeStamp = OPTION.timeStamp;
				}
				if(FORM_OBJ.timeStampSrt == 'あり' || FORM_OBJ.timeStampSrt == 'あり-＜1＞のみ' || FORM_OBJ.timeStampSrt == 'あり-両方'){
					data1_timeStampSrt = OPTION.timeStampSrt;
				}
				if(FORM_OBJ.selfRole == 'あり' || FORM_OBJ.selfRole == 'あり-＜1＞のみ' || FORM_OBJ.selfRole == 'あり-両方'){
					data1_selfRole = OPTION.selfRole;
				}
				if(FORM_OBJ.webData == 'あり' || FORM_OBJ.webData == 'あり-＜1＞のみ' || FORM_OBJ.webData == 'あり-両方'){
					data1_webData = OPTION.webData;
				}

				// console.log('データ1');
				// console.log(
				// 	'話者人数オプション：' + data1_wasyaNum,
				// 	'話者表記：' + data1_talkNumber,
				// 	'専門性の高い文字起こし：'+ data1_expertise,
				// 	'タイム表記：' + data1_timeStamp,
				// 	'タイム表記SRT：' + data1_timeStampSrt,
				// 	'独自書式対応：' + data1_selfRole,
				// 	'web上のデータ：' + data1_webData,
				// 	'土日祝：' + holidayDelivery,
				// 	'データ返却：' + returnData
				// );


				data1_price = (FORM_OBJ.ttime1_Total * (data1_base_price + data1_talkNumber + data1_wasyaNum + data1_expertise + data1_timeStamp + data1_timeStampSrt + data1_selfRole)) + data1_webData;
				total_price = data1_price + data2_price + holidayDelivery + returnData + document;
				total_price_taxin = Math.floor((total_price * 1.1));
				// $('.quote-price-block .price').text(total_price.toLocaleString());
				$('.quote-price-block .price').text(total_price_taxin.toLocaleString());
				$('#estimate').val(total_price_taxin.toLocaleString());

			}


			//データ２の計算//////////////////////////////////////////////////////////////////////////////////////////////////////
			const data2Calc = () => {
				if(isNaN(FORM_OBJ.plan) || isNaN(FORM_OBJ.format2) || !FORM_OBJ.speakerNumber2) return;


				let data2_base_price = PRICE[FORM_OBJ.format2].price[FORM_OBJ.plan];
				let data2_talkNumber = OPTION.wasya[FORM_OBJ.talkNumber2];

				let data2_wasyaNum = 0;
				let data2_expertise = 0;
				let data2_timeStamp = 0;
				let data2_timeStampSrt = 0;
				let data2_selfRole = 0;

				let data2_webData = 0;

				// console.log('ベース料金：' + data1_base_price);
				// console.log('話者人数オプション：' + data1_wasya_price);


				if(FORM_OBJ.speakerNumber2 == '6人以上'){
					data2_wasyaNum = OPTION.wasyaNum;
				}

				if(FORM_OBJ.expertise == 'あり-＜2＞のみ' || FORM_OBJ.expertise == 'あり-両方'){
					data2_expertise = OPTION.expertise;
				}
				if(FORM_OBJ.timeStamp == 'あり-＜2＞のみ' || FORM_OBJ.timeStamp == 'あり-両方'){
					data2_timeStamp = OPTION.timeStamp;
				}
				if(FORM_OBJ.timeStampSrt == 'あり-＜2＞のみ' || FORM_OBJ.timeStampSrt == 'あり-両方'){
					data2_timeStampSrt = OPTION.timeStampSrt;
				}
				if(FORM_OBJ.selfRole == 'あり-＜2＞のみ' || FORM_OBJ.selfRole == 'あり-両方'){
					data2_selfRole = OPTION.selfRole;
				}
				if(FORM_OBJ.webData == 'あり-＜2＞のみ' || FORM_OBJ.webData == 'あり-両方'){
					data2_webData = OPTION.webData;
				}

				// console.log('データ2');
				// console.log(
				// 	'話者人数オプション：' + data2_wasyaNum,
				// 	'話者表記：' + data2_talkNumber,
				// 	'専門性の高い文字起こし：'+ data2_expertise,
				// 	'タイム表記：' + data2_timeStamp,
				// 	'タイム表記SRT：' + data2_timeStampSrt,
				// 	'独自書式対応：' + data2_selfRole,
				// 	'web上のデータ：' + data2_webData,
				// 	'土日祝：' + holidayDelivery,
				// 	'データ返却：' + returnData
				// );


				data2_price = (FORM_OBJ.ttime2_Total * (data2_base_price + data2_talkNumber + data2_wasyaNum + data2_expertise + data2_timeStamp + data2_timeStampSrt + data2_selfRole)) + data2_webData;
				total_price = data1_price + data2_price + holidayDelivery + returnData + document;
				total_price_taxin = Math.floor((total_price * 1.1));
				// $('.quote-price-block .price').text(total_price.toLocaleString());
				$('.quote-price-block .price').text(total_price_taxin.toLocaleString());
				$('#estimate').val(total_price_taxin.toLocaleString());

			}
			///////////////////////////////////////////////////////////////////////////////////////////////////////////


			if( 3 <= parseInt($('#fileNumber').val()) || $('input[name="inputSelect"]:checked').val() == 'false' || FORM_OBJ.totalTime < 1){
				$('.quote-price-block .price').text('0');
				$('#estimate').val('0');
			} else if(parseInt($('#fileNumber').val()) == 1){
				data1Calc();
			} else if(parseInt($('#fileNumber').val()) == 2){
				data1Calc();
				data2Calc();
			}

		},

		quoteSet(){
			const A = this;

			//データ時間をしゅとくするやつ
			A.timeSet();

		},

		dataSelectReset(){ //オプションのoptionの出し分けをリセット
			$('#expertise option.u-hidden').removeClass('u-hidden').unwrap('<span class="u-hidden">');
			$('#timeStamp option.u-hidden').removeClass('u-hidden').unwrap('<span class="u-hidden">');
			$('#timeStampSrt option.u-hidden').removeClass('u-hidden').unwrap('<span class="u-hidden">');
			$('#selfRole option.u-hidden').removeClass('u-hidden').unwrap('<span class="u-hidden">');
			$('#webData option.u-hidden').removeClass('u-hidden').unwrap('<span class="u-hidden">');
		},

		data1SelectInitial(){ //オプションのoptionの出し分け データ1個の場合
			const A = this;

			A.dataSelectReset();

			$('#expertise option:nth-child(n+3)').addClass('u-hidden').wrap('<span class="u-hidden">');
			$('#timeStamp option:nth-child(n+3)').addClass('u-hidden').wrap('<span class="u-hidden">');
			$('#timeStampSrt option:nth-child(n+3)').addClass('u-hidden').wrap('<span class="u-hidden">');
			$('#selfRole option:nth-child(n+3)').addClass('u-hidden').wrap('<span class="u-hidden">');
			$('#webData option:nth-child(n+3)').addClass('u-hidden').wrap('<span class="u-hidden">');

			$('#expertise').val('なし').trigger('change');
			$('#timeStamp').val('なし').trigger('change');
			$('#timeStampSrt').val('なし').trigger('change');
			$('#selfRole').val('なし').trigger('change');
			$('#webData').val('なし').trigger('change');
		},

		data2SelectInitial(){ //オプションのoptionの出し分け データ2個の場合
			const A = this;

			A.dataSelectReset();

			$('#expertise option:nth-child(2)').addClass('u-hidden').wrap('<span class="u-hidden">');
			$('#timeStamp option:nth-child(2)').addClass('u-hidden').wrap('<span class="u-hidden">');
			$('#timeStampSrt option:nth-child(2)').addClass('u-hidden').wrap('<span class="u-hidden">');
			$('#selfRole option:nth-child(2)').addClass('u-hidden').wrap('<span class="u-hidden">');
			$('#webData option:nth-child(2)').addClass('u-hidden').wrap('<span class="u-hidden">');
		},

		data1FormInitial(){ //データ1つの場合の表示
			const A = this;
			$('#formData1Detail').removeClass('u-hidden');
			$('#formData2Detail').addClass('u-hidden');
			$('#formOverDataAlert').addClass('u-hidden');
			$('#formFormat2').addClass('u-hidden');
		},
		data2FormInitial(){  //データ2つの場合の表示
			const A = this;
			$('#formData1Detail').removeClass('u-hidden');
			$('#formData2Detail').removeClass('u-hidden');
			$('#formOverDataAlert').addClass('u-hidden');
			$('#formFormat2').removeClass('u-hidden');
		},
		overDataFormInitial(){  //データ3つ以上の場合の表示
			const A = this;
			$('#formData1Detail').addClass('u-hidden');
			$('#formData2Detail').addClass('u-hidden');
			$('#formOverDataAlert').removeClass('u-hidden');
		},
		dataFormHidden(){ //データ詳細入力画面すべて非表示
			$('#formData1Detail').addClass('u-hidden');
			$('#formData2Detail').addClass('u-hidden');
			$('#formOverDataAlert').addClass('u-hidden');
		},

		dataBaseFormInitial(){ //データ入力のベースフォーム表示
			$('#formFileNumber').removeClass('u-hidden');
			$('#formVoiceDetail').removeClass('u-hidden');
		},
		dataBaseFormHidden(){ //データ入力のベースフォーム非表示
			$('#formFileNumber').addClass('u-hidden');
			$('#formVoiceDetail').addClass('u-hidden');
		},

		omitAlertInitial(){
			$('#formOmitAlert').removeClass('u-hidden');
			$('#formFormat2').addClass('u-hidden');
			$('#formTalkNumber2').addClass('u-hidden');
		},
		omitAlertHidden(){
			$('#formOmitAlert').addClass('u-hidden');
		},

		formTalkNumber1Initial(){
			const value = parseInt($('#talkNumber1').val());

			// console.log(value);

			$('#formTalkNumber1Detail .form-block__part').addClass('u-hidden');
			if(value == 1){
				$('#formTalkNumber1Detail .form-block__part:nth-child(1)').removeClass('u-hidden');
			} else if(value == 2){
				$('#formTalkNumber1Detail .form-block__part:nth-child(-n+2)').removeClass('u-hidden');
			} else if(value == 3){
				$('#formTalkNumber1Detail .form-block__part:nth-child(-n+3)').removeClass('u-hidden');
			} else if(value == 4){
				$('#formTalkNumber1Detail .form-block__part:nth-child(-n+4)').removeClass('u-hidden');
			} else if(value == 5){
				$('#formTalkNumber1Detail .form-block__part').removeClass('u-hidden');
			}
		},

		formTalkNumber2Initial(){
			const value = parseInt($('#talkNumber2').val());

			// console.log(value);

			$('#formTalkNumber2Detail .form-block__part').addClass('u-hidden');
			if(value == 1){
				$('#formTalkNumber2Detail .form-block__part:nth-child(1)').removeClass('u-hidden');
			} else if(value == 2){
				$('#formTalkNumber2Detail .form-block__part:nth-child(-n+2)').removeClass('u-hidden');
			} else if(value == 3){
				$('#formTalkNumber2Detail .form-block__part:nth-child(-n+3)').removeClass('u-hidden');
			} else if(value == 4){
				$('#formTalkNumber2Detail .form-block__part:nth-child(-n+4)').removeClass('u-hidden');
			} else if(value == 5){
				$('#formTalkNumber2Detail .form-block__part').removeClass('u-hidden');
			}
		},

		formTimeStampInitial(){
			const value = $('#timeStamp').val();

			$('#formTimeStampDetail .row-block').addClass('u-hidden');
			if(value == 'あり' ||  value == 'あり-＜1＞のみ'){
				$('#formTimeStampDetail .row-block:nth-child(1)').removeClass('u-hidden');
			} else if(value == 'あり-＜2＞のみ'){
				$('#formTimeStampDetail .row-block:nth-child(2)').removeClass('u-hidden');
			} else if(value == 'あり-両方'){
				$('#formTimeStampDetail .row-block:nth-child(1)').removeClass('u-hidden');
				$('#formTimeStampDetail .row-block:nth-child(2)').removeClass('u-hidden');
			}
		},


		initialSetting(){
			const A = this;

			A.data1SelectInitial();
			// A.data1FormInitial();
			// A.data2FormInitial();
			// A.overDataFormInitial()

			A.dataBaseFormHidden();
			A.dataFormHidden();
			A.omitAlertHidden();

			A.formTalkNumber1Initial();
			A.formTalkNumber2Initial();
			A.formTimeStampInitial();


		},

		setNonSelect(idname){
			// 未選択状態にする
			const obj = document.getElementById(idname);
			obj.selectedIndex = -1;
		},

		loadSelectInitial(){
			const A = this;

			A.setNonSelect('plan');
			A.setNonSelect('fileNumber');
			A.setNonSelect('speakerNumber1');
			A.setNonSelect('speakerNumber2');
			A.setNonSelect('format1');
			A.setNonSelect('format2');
			A.setNonSelect('history');
		},

		formatInitial(_boolen){
			const A = this;

			if(_boolen){
				// 翌日当日が選ばれた時
				$('#format1 option:nth-child(n+3)').addClass('u-hidden').wrap('<span class="u-hidden">');
				$('#format2 option:nth-child(n+3)').addClass('u-hidden').wrap('<span class="u-hidden">');

				$('#format1').parents('.set-placeholder').addClass('selected');
				$('#format2').parents('.set-placeholder').addClass('selected');

				// setTimeout(() => {
				// 	$('#format1').val('0').trigger('change');
				// 	$('#format2').val('0').trigger('change');
				// },100)
				setTimeout(() => {
					if(parseInt(FORM_OBJ.format1Before) == 2 || parseInt(FORM_OBJ.format1Before) == 3){
						$('#format1').val('0');
					} else if(FORM_OBJ.format1Before == 1){
						$('#format1').val('1');
					}
					if(parseInt(FORM_OBJ.format2Before) == 2 || parseInt(FORM_OBJ.format2Before) == 3){
						$('#format2').val('0');
					} else if(FORM_OBJ.format2Before == 1){
						$('#format1').val('1');
					}
				},30)
			} else {
				$('#format1 option.u-hidden').removeClass('u-hidden').unwrap('<span class="u-hidden">');
				$('#format2 option.u-hidden').removeClass('u-hidden').unwrap('<span class="u-hidden">');

				setTimeout(() => {
					$('#format1').val(FORM_OBJ.format1Before).trigger('change');
					$('#format2').val(FORM_OBJ.format2Before).trigger('change');
				},30)
			}
		},

		optionInitial(_boolen, _value, _holidaysData){
			const A = this;

			// 話者表記2人まで

			// 話者表記以外のオプションは選べない
			// オプションリセット

			// A.dayOfWeekGet(FORM_OBJ.preferredDate, _holidaysData);
			// console.log(_value,_holidaysData);

			if(_boolen){
				// 翌日当日が選ばれた時
				$('#formExpertise').addClass('u-hidden');
				$('#formTimeStamp').addClass('u-hidden');
				$('#formTimeStampSrt').addClass('u-hidden');
				$('#formSelfRole').addClass('u-hidden');
				$('#formWebData').addClass('u-hidden');
				$('#formHolidayDelivery .form-block__part-content__note-list').addClass('u-c-base');
				$('#formReturnData').addClass('u-hidden');

				$('#expertise').val('なし');
				$('#timeStamp').val('なし').trigger('change');
				$('#timeStampSrt').val('なし');
				$('#selfRole').val('なし');
				$('#webData').val('なし');
				// $('#holidayDelivery').val('なし');
				$('#formReturnData').val('なし');

				if(FORM_OBJ.speakerNumber1 != '6人以上'){
					$('#talkNumber1 option:nth-child(n+4)').addClass('u-hidden').wrap('<span class="u-hidden">');
				}
				setTimeout(() => {
					if(FORM_OBJ.talkNumber1Before >= 3){
						if(FORM_OBJ.speakerNumber1 != '6人以上'){
							$('#talkNumber1').val('2').trigger('change');
						}
					}
				},30);

				if(FORM_OBJ.speakerNumber2 != '6人以上'){
					$('#talkNumber2 option:nth-child(n+4)').addClass('u-hidden').wrap('<span class="u-hidden">');
				}
				setTimeout(() => {
					if(FORM_OBJ.talkNumber2Before >= 3){
						if(FORM_OBJ.speakerNumber2 != '6人以上'){
							$('#talkNumber2').val('2').trigger('change');
						}
					}
				},30);
			} else {
				$('#formExpertise').removeClass('u-hidden');
				$('#formTimeStamp').removeClass('u-hidden');
				$('#formTimeStampSrt').removeClass('u-hidden');
				$('#formSelfRole').removeClass('u-hidden');
				$('#formWebData').removeClass('u-hidden');
				$('#formHolidayDelivery .form-block__part-content__note-list').removeClass('u-c-base');
				$('#formReturnData').removeClass('u-hidden');

				if(FORM_OBJ.speakerNumber1 != '6人以上'){
					$('#talkNumber1 option.u-hidden').removeClass('u-hidden').unwrap('<span class="u-hidden">');
				}
				if(FORM_OBJ.speakerNumber2 != '6人以上'){
					$('#talkNumber2 option.u-hidden').removeClass('u-hidden').unwrap('<span class="u-hidden">');
				}

				setTimeout(() => {
					$('#talkNumber1').val(FORM_OBJ.talkNumber1Before).trigger('change');
					$('#talkNumber2').val(FORM_OBJ.talkNumber2Before).trigger('change');
				},30);

			}
		},

		formTalkNumberInitial_wasyaNum(){
			if(FORM_OBJ.speakerNumber1 == '6人以上'){
				$('#talkNumber1 option:nth-child(n+2)').addClass('u-hidden').wrap('<span class="u-hidden">');
				$('#formTalkNumber1Detail .form-block__part').addClass('u-hidden');
			} else {
				$('#talkNumber1 option.u-hidden').removeClass('u-hidden').unwrap('<span class="u-hidden">');
				setTimeout(() => {
					$('#talkNumber1').val('0').trigger('change');
				},30);
			}
			if(FORM_OBJ.speakerNumber2 == '6人以上'){
				$('#talkNumber2 option:nth-child(n+2)').addClass('u-hidden').wrap('<span class="u-hidden">');
				$('#formTalkNumber2Detail .form-block__part').addClass('u-hidden');
			} else {
				$('#talkNumber2 option.u-hidden').removeClass('u-hidden').unwrap('<span class="u-hidden">');
				setTimeout(() => {
					$('#talkNumber2').val('0').trigger('change');
				},30);
			}
		},

		planValidation(_holidaysData){ //プランが当日、翌日の時の制御
			const A = this;
			const value = parseInt($('#plan').val());
			let flag = false;

			if(3 <= value){
				$('.form-block__part-content--plan .form-block__part-content__note-list').addClass('u-c-base');
				flag = true;
			} else {
				$('.form-block__part-content--plan .form-block__part-content__note-list').removeClass('u-c-base');
				flag = false;
			}

			A.formatInitial(flag);
			A.optionInitial(flag, value,_holidaysData);

			return flag;
		},


		fileNameBind(){
			const A = this;
			const $fileName1 = $('#fileName1');
			const $fileName2 = $('#fileName2');

			const $label1 = $('.label-filename__file1');
			const $label2 = $('.label-filename__file2');

			$fileName1.on('blur', (e) => {
				const value = $(e.currentTarget).val();
				$label1.text(value);
			});
			$fileName2.on('blur', (e) => {
				const value = $(e.currentTarget).val();
				$label2.text(value);
			});


		},


		formValidate(){
			const A = this


			$.validator.addMethod(
				"regex",
				function(value, element, regexp) {
					var check = false;
					return this.optional(element) || regexp.test(value);
				},
				// メッセージを省略
			);


			let validator = $('#orderForm').validate({
				rules: {
					inputSelect: {
						required: true,
					},

					fileNumber: {
						required: () => {
							return $('input[name="inputSelect"]:checked').val() == 'true';
						}
					},
					voiceDetail: {
						required: () => {
							return $('input[name="inputSelect"]:checked').val() == 'true';
						}
					},
					fileName1: {
						required: () => {
							return parseInt($('#fileNumber').val()) <= 2;
						},
					},
					fileName2: {
						required: () => {
							return parseInt($('#fileNumber').val()) == 2;
						},
					},


					format1: {
						required: () => {
							if(parseInt($('#fileNumber').val()) <= 2 || $('input[name="inputSelect"]:checked').val() == 'false'){
								return true;
							} else {
								return false;
							}
						},
					},
					format2: {
						required: () => {
							return parseInt($('#fileNumber').val()) == 2;
						},
					},
					preferredDate: {
						required: false,
						date: true
					},
					plan: {
						required: true,
					},
					history: {
						required: true,
					},
					corporate: {
						required: true,
					},
					name_1: {
						required: true,
					},
					name_2: {
						required: true,
					},
					read_1: {
						required: true,
						regex : /^[ぁ-んー]+$/,
					},
					read_2: {
						required: true,
						regex : /^[ぁ-んー]+$/,
					},
					postal: {
						required: true,
						number: true,
						minlength: 7,
						maxlength: 7,
					},
					address: {
						required: true,
					},
					phone: {
						required: true,
						number: true,
					},
					mail_address: {
						required: true,
						email: true
					},
					concent: {
						required: true,
					},
					caution_concent: {
						required: true,
					},
				},
				messages: {
					inputSelect: {
						required: '※必須項目が入力されていません',
					},
					fileNumber: {
						required: '※必須項目が入力されていません',
					},
					voiceDetail: {
						required: '※必須項目が入力されていません',
					},
					fileName1: {
						required: '※必須項目が入力されていません',
					},

					fileName2: {
						required: '※必須項目が入力されていません',
					},

					format1: {
						required: '※必須項目が入力されていません',
					},
					format2: {
						required: '※必須項目が入力されていません',
					},

					preferredDate: {
						date: '※形式が正しくありません'
					},
					plan: {
						required: '※必須項目が入力されていません',
					},
					history: {
						required: '※必須項目が入力されていません',
					},
					corporate: {
						required: '※必須項目が入力されていません',
					},
					name_1: {
						required: '※必須項目が入力されていません',
					},
					name_2: {
						required: '※必須項目が入力されていません',
					},
					read_1: {
						required: '※必須項目が入力されていません',
						regex : '※ひらがなでご入力ください',
					},
					read_2: {
						required: '※必須項目が入力されていません',
						regex : '※ひらがなでご入力ください',
					},
					postal: {
						required: '※必須項目が入力されていません',
						number: '※半角数でご入力ください',
						minlength: '※7桁でご入力ください',
						maxlength: '※7桁でご入力ください',
					},
					address: {
						required: '※必須項目が入力されていません',
					},
					phone: {
						required: '※必須項目が入力されていません',
						number: '※半角数でご入力ください',
					},
					mail_address: {
						required: '※必須項目が入力されていません',
						email: '※メールアドレスの形式が正しくありません'
					},
					concent: {
						required: '※同意されていません',
					},
					caution_concent: {
						required: '※同意されていません',
					},
				},
				errorPlacement: function(error, element){
					var errorKey = $(element).attr('id') + 'Error';
					$('#error_' + errorKey).remove();
					element.addClass('is-invalid');
					const errorP = $('<p>').text(error[0].innerText);
					const errorDiv = $('<div class="invalid-feedback" id="error_' + errorKey + '">').append(errorP);
					element.parent().append(errorDiv);
				},
				success: function(error, element) {
					var errorKey = $(element).attr('id') + 'Error';
					$('#error_' + errorKey).remove();
					$(error).remove();
					$(element).removeClass('is-invalid');
					$(element).removeClass('error');
				},

				invalidHandler(){
					setTimeout(() => {
						const index = $('.invalid-feedback').eq(0).parents('.form-block__panel').index();
						// console.log('エラー', index);
						$('.step-list li').eq(index).trigger('click');
					},20);
				}
			});

			// invalid-feedback

		},



		selectNumSet(){
			const util = new Util();
			util.selectSet('#fileNumber', 1, 100);

			util.selectSet('#ttime_h', 0, 100);
			util.selectSet('#ttime_m', 0, 59);
			util.selectSet('#ttime_s', 0, 59);

			util.selectSet('#ttime1_s_h', 0, 100);
			util.selectSet('#ttime1_s_m', 0, 59);
			util.selectSet('#ttime1_s_s', 0, 59);

			util.selectSet('#ttime1_e_h', 0, 100);
			util.selectSet('#ttime1_e_m', 0, 59);
			util.selectSet('#ttime1_e_s', 0, 59);

			util.selectSet('#ttime2_s_h', 0, 100);
			util.selectSet('#ttime2_s_m', 0, 59);
			util.selectSet('#ttime2_s_s', 0, 59);

			util.selectSet('#ttime2_e_h', 0, 100);
			util.selectSet('#ttime2_e_m', 0, 59);
			util.selectSet('#ttime2_e_s', 0, 59);

			util.selectSet('#peopleTime_h_1_1', 0, 100);
			util.selectSet('#peopleTime_m_1_1', 0, 59);
			util.selectSet('#peopleTime_s_1_1', 0, 59);

			util.selectSet('#peopleTime_h_1_2', 0, 100);
			util.selectSet('#peopleTime_m_1_2', 0, 59);
			util.selectSet('#peopleTime_s_1_2', 0, 59);

			util.selectSet('#peopleTime_h_1_3', 0, 100);
			util.selectSet('#peopleTime_m_1_3', 0, 59);
			util.selectSet('#peopleTime_s_1_3', 0, 59);

			util.selectSet('#peopleTime_h_1_4', 0, 100);
			util.selectSet('#peopleTime_m_1_4', 0, 59);
			util.selectSet('#peopleTime_s_1_4', 0, 59);

			util.selectSet('#peopleTime_h_1_5', 0, 100);
			util.selectSet('#peopleTime_m_1_5', 0, 59);
			util.selectSet('#peopleTime_s_1_5', 0, 59);

			util.selectSet('#peopleTime_h_2_1', 0, 100);
			util.selectSet('#peopleTime_m_2_1', 0, 59);
			util.selectSet('#peopleTime_s_2_1', 0, 59);

			util.selectSet('#peopleTime_h_2_2', 0, 100);
			util.selectSet('#peopleTime_m_2_2', 0, 59);
			util.selectSet('#peopleTime_s_2_2', 0, 59);

			util.selectSet('#peopleTime_h_2_3', 0, 100);
			util.selectSet('#peopleTime_m_2_3', 0, 59);
			util.selectSet('#peopleTime_s_2_3', 0, 59);

			util.selectSet('#peopleTime_h_2_4', 0, 100);
			util.selectSet('#peopleTime_m_2_4', 0, 59);
			util.selectSet('#peopleTime_s_2_4', 0, 59);

			util.selectSet('#peopleTime_h_2_5', 0, 100);
			util.selectSet('#peopleTime_m_2_5', 0, 59);
			util.selectSet('#peopleTime_s_2_5', 0, 59);

			$('#fileNumber').append('<option value="100以上">100以上</option>');
		},

		stepFadeIn(_el){
			_el.addClass('is-active');
			gsap.set(_el, {
				'position': 'relative'
			});
			gsap.to(_el, {
				'opacity': 1,
				duration: 0.3
			});
		},

		stepHide(_el){
			gsap.set(_el, {
				'position': 'absolute',
				'opacity': 0
			});
		},


		formStep(){
			const A = this;
			const $stepPanel = $('.form-block__panel');
			const $quoteBlock = $('.quote-block');

			$('.step-list li').eq(0).addClass('is-active');
			A.stepFadeIn($('.form-block__panel').eq(0));
			$quoteBlock.delay(200).fadeIn();

			$('.step-list li').on('click', (e) => {
				const $this = $(e.currentTarget);
				let index = $this.index();
				if($this.hasClass('is-clear')){
					$('.step-list li').removeClass('is-active');
					$this.addClass('is-active');

					A.stepHide($('.form-block__panel.is-active'));
					A.stepFadeIn($('.form-block__panel').eq(index));
				}
			});


			const util = new Util();

			$('.form-block__pager-btn').on('click', () => {
				util.ankerScroll('main-content');
			});

			A.step1();
			A.step2();
			A.step3();
			A.step4();
			A.step5();
			A.step6();

			// $('.form-block__pager-btn--next').removeClass('is-no-click');

		},

		step1Control(){
			// console.log(FORM_OBJ.fileNumber, FORM_OBJ.voiceDetail,$.cookie('xlsxDownload'));
			if($('input[name="inputSelect"]:checked').val() == 'true'){
				// console.log('ture');
				if(FORM_OBJ.fileNumber >= 3 && FORM_OBJ.voiceDetail && $.cookie('xlsxDownload')){
					$('.step-list li').eq(2).addClass('is-no-click');
					$('.step-list li').eq(1).addClass('is-clear');
					$('#form-step1 .form-block__pager-btn--next').removeClass('is-no-click');
					$('#form-step1 .form-block__pager__validate-txt').addClass('txt-hidden');
					// console.log('こっち');
				} else {
					$('.step-list li').eq(2).removeClass('is-no-click');
					$('#form-step1 .form-block__pager-btn--next').addClass('is-no-click');
					$('#form-step1 .form-block__pager__validate-txt').removeClass('txt-hidden');
					// console.log('あっち');
				}

				if(FORM_OBJ.fileNumber == 1){
					if(FORM_OBJ.voiceDetail && FORM_OBJ.fileName1){
						$('#form-step1 .form-block__pager-btn--next').removeClass('is-no-click');
						$('#form-step1 .form-block__pager__validate-txt').addClass('txt-hidden');
						$('.step-list li').eq(1).addClass('is-clear');
						// console.log('それともこっち');
					} else {
						$('#form-step1 .form-block__pager-btn--next').addClass('is-no-click');
						$('#form-step1 .form-block__pager__validate-txt').removeClass('txt-hidden');
						// console.log('それともあっち');
					}
				}

				if(FORM_OBJ.fileNumber == 2){
					if(FORM_OBJ.voiceDetail && FORM_OBJ.fileName1 && FORM_OBJ.fileName2){
						$('#form-step1 .form-block__pager-btn--next').removeClass('is-no-click');
						$('#form-step1 .form-block__pager__validate-txt').addClass('txt-hidden');
						$('.step-list li').eq(1).addClass('is-clear');
						// console.log('いやいやこっち');
					} else {
						$('#form-step1 .form-block__pager-btn--next').addClass('is-no-click');
						$('#form-step1 .form-block__pager__validate-txt').removeClass('txt-hidden');
						// console.log('いやいやあっち');
					}
				}


			} else if($('input[name="inputSelect"]:checked').val() == 'false'){

			} else {
				$('.step-list li').eq(2).removeClass('is-no-click');
			}

		},

		step1(){
			const A = this;
			$('input[name="inputSelect"]').on('change', (e) => {
				if($(e.currentTarget).val() == 'false'){
					$('#form-step1 .form-block__pager-btn--next').removeClass('is-no-click');
					$('#form-step1 .form-block__pager__validate-txt').addClass('txt-hidden');
					$('.step-list li').eq(1).addClass('is-clear');
					// console.log('ここ')
				} else {
					$('#form-step1 .form-block__pager-btn--next').addClass('is-no-click');
					$('#form-step1 .form-block__pager__validate-txt').removeClass('txt-hidden');
					$('.step-list li').eq(1).removeClass('is-clear');
				}
				if(FORM_OBJ.fileNumber >= 3 && $('input[name="inputSelect"]:checked').val() == 'true'){
					$('.step-list li').eq(2).addClass('is-no-click');
				} else {
					$('.step-list li').eq(2).removeClass('is-no-click');
					// $('.step-list li').eq(1).addClass('is-clear');
				}
			});


			$('#fileNumber').on('change', (e) => {
				FORM_OBJ.fileNumber = $(e.currentTarget).val();
				A.step1Control();
			});
			$('#voiceDetail').on('change', (e) => {
				FORM_OBJ.voiceDetail = $(e.currentTarget).val();
				A.step1Control();
			});

			$('.xlsx-download-btn').on('click', () => {
				FORM_OBJ.xlsxDownload = true;
				$.cookie('xlsxDownload','downloaded', {expires: 1});
				A.step1Control();
			});

			$('#fileName1').on('change', (e) => {
				FORM_OBJ.fileName1 = $(e.currentTarget).val();
				A.step1Control();
			});
			$('#fileName2').on('change', (e) => {
				FORM_OBJ.fileName2 = $(e.currentTarget).val();
				A.step1Control();
			});



			$('.step-list li').eq(0).addClass('is-clear');

			$('#form-step1 .form-block__pager-btn--next').on('click', () => {
				A.stepHide($('#form-step1'));
				$('.form-block__panel').removeClass('is-active');

				A.stepFadeIn($('#form-step2'));
				$('.step-list li').removeClass('is-active');
				// $('.step-list li').eq(1).addClass('is-clear');
				$('.step-list li').eq($('.form-block__panel.is-active').index()).addClass('is-active');
			});
		},

		step2Control(){
			// console.log(FORM_OBJ.plan);
			if(!isNaN(FORM_OBJ.plan) && FORM_OBJ.plan != null){
				$('#form-step2 .form-block__pager-btn--next').removeClass('is-no-click');
				$('#form-step2 .form-block__pager__validate-txt').addClass('txt-hidden');
				if(FORM_OBJ.fileNumber <= 2){
					$('.step-list li').eq(2).addClass('is-clear');
				}
				if(FORM_OBJ.fileNumber > 2 && FORM_OBJ.inputSelect == 'true'){
					$('.step-list li').eq(3).addClass('is-clear');
					// console.log('どこ')
				}
			} else {
				$('#form-step2 .form-block__pager-btn--next').addClass('is-no-click');
				$('#form-step2 .form-block__pager__validate-txt').removeClass('txt-hidden');
			}
		},

		step2(){
			const A = this;

			$('#plan').on('change', (e) => {
				FORM_OBJ.plan = $(e.currentTarget).val();
				// console.log(FORM_OBJ.plan);
				A.step2Control();
			});


			$('#form-step2 .form-block__pager-btn--prev').on('click', () => {
				A.stepHide($('#form-step2'));
				A.stepFadeIn($('#form-step1'));
				$('.step-list li').removeClass('is-active');
				$('.step-list li').eq($('.form-block__panel.is-active').index()).addClass('is-active');
			});

			$('#form-step2 .form-block__pager-btn--next').on('click', () => {
				A.stepHide($('#form-step2'));
				$('.form-block__panel').removeClass('is-active');
				$('.step-list li').removeClass('is-active');
				// $('.step-list li').eq(2).addClass('is-clear');

				if(FORM_OBJ.fileNumber <= 2 || FORM_OBJ.inputSelect == 'false'){
					A.stepFadeIn($('#form-step3'));
					A.step3Control();
				} else {
					A.stepFadeIn($('#form-step4'));
				}
				$('.step-list li').eq($('.form-block__panel.is-active').index()).addClass('is-active');
			});
		},

		step3Control(){
			// console.log(FORM_OBJ.inputSelect,FORM_OBJ.format1,FORM_OBJ.format2);

			if(FORM_OBJ.inputSelect == 'false'){
				if(FORM_OBJ.format1 != null && !isNaN(FORM_OBJ.format1)){
					$('#form-step3 .form-block__pager-btn--next').removeClass('is-no-click');
					$('#form-step3 .form-block__pager__validate-txt').addClass('txt-hidden');
					$('.step-list li').eq(3).addClass('is-clear');
					// console.log('ここ')
				} else {
					$('#form-step3 .form-block__pager-btn--next').addClass('is-no-click');
					$('#form-step3 .form-block__pager__validate-txt').removeClass('txt-hidden');
				}
			}

			if(FORM_OBJ.inputSelect == 'true' &&  FORM_OBJ.fileNumber == 1){
				if(FORM_OBJ.format1 != null && !isNaN(FORM_OBJ.format1)){
					$('#form-step3 .form-block__pager-btn--next').removeClass('is-no-click');
					$('#form-step3 .form-block__pager__validate-txt').addClass('txt-hidden');
					$('.step-list li').eq(3).addClass('is-clear');
					// console.log('あそこ')
				}
			}

			if(FORM_OBJ.inputSelect == 'true' &&  FORM_OBJ.fileNumber == 2){
				if(FORM_OBJ.format1 != null && !isNaN(FORM_OBJ.format1) && FORM_OBJ.format2 != null && !isNaN(FORM_OBJ.format2)){
					$('#form-step3 .form-block__pager-btn--next').removeClass('is-no-click');
					$('#form-step3 .form-block__pager__validate-txt').addClass('txt-hidden');
					$('.step-list li').eq(3).addClass('is-clear');
					// console.log('あそこ')
				}
			}
		},


		step3(){
			const A = this;

			$('#format1').on('change', (e) => {
				FORM_OBJ.format1 = $(e.currentTarget).val();
				A.step3Control();
			});
			$('#format2').on('change', (e) => {
				FORM_OBJ.format2 = $(e.currentTarget).val();
				A.step3Control();
			});



			$('#form-step3 .form-block__pager-btn--prev').on('click', () => {
				A.stepHide($('#form-step3'));
				A.stepFadeIn($('#form-step2'));
				$('.step-list li').removeClass('is-active');
				$('.step-list li').eq($('.form-block__panel.is-active').index()).addClass('is-active');
			});

			$('#form-step3 .form-block__pager-btn--next').on('click', () => {
				A.stepHide($('#form-step3'));
				$('.form-block__panel').removeClass('is-active');
				$('.step-list li').removeClass('is-active');
				// $('.step-list li').eq(3).addClass('is-clear');
				A.stepFadeIn($('#form-step4'));
				$('.step-list li').eq($('.form-block__panel.is-active').index()).addClass('is-active');
			});
		},

		step4Control(){
			if(FORM_OBJ.history && FORM_OBJ.corporate && FORM_OBJ.name_1 && FORM_OBJ.name_2 && FORM_OBJ.read_1 && FORM_OBJ.read_2 && FORM_OBJ.postal && FORM_OBJ.address && FORM_OBJ.phone &&  FORM_OBJ.mail_address){
				$('#form-step4 .form-block__pager-btn--next').removeClass('is-no-click');
				$('#form-step4 .form-block__pager__validate-txt').addClass('txt-hidden');
				$('.step-list li').eq(4).addClass('is-clear');
			} else {
				$('#form-step4 .form-block__pager-btn--next').addClass('is-no-click');
				$('#form-step4 .form-block__pager__validate-txt').removeClass('txt-hidden');
			}
		},

		step4(){
			const A = this;

			$('#history').on('change', (e) => {
				FORM_OBJ.history = $(e.currentTarget).val();
				A.step4Control();
				A.step5Control();
			});
			$('#corporate').on('change', (e) => {
				FORM_OBJ.corporate = $(e.currentTarget).val();
				A.step4Control();
				A.step5Control();
			});
			$('#name_1').on('change', (e) => {
				FORM_OBJ.name_1 = $(e.currentTarget).val();
				A.step4Control();
				A.step5Control();
			});
			$('#name_2').on('change', (e) => {
				FORM_OBJ.name_2 = $(e.currentTarget).val();
				A.step4Control();
				A.step5Control();
			});
			$('#read_1').on('change', (e) => {
				FORM_OBJ.read_1 = $(e.currentTarget).val();
				A.step4Control();
				A.step5Control();
			});
			$('#read_2').on('change', (e) => {
				FORM_OBJ.read_2 = $(e.currentTarget).val();
				A.step4Control();
				A.step5Control();
			});
			$('#postal').on('change', (e) => {
				FORM_OBJ.postal = $(e.currentTarget).val();
				A.step4Control();
				A.step5Control();
			});
			$('#address').on('change', (e) => {
				FORM_OBJ.address = $(e.currentTarget).val();
				A.step4Control();
				A.step5Control();
			});
			$('#phone').on('change', (e) => {
				FORM_OBJ.phone = $(e.currentTarget).val();
				A.step4Control();
				A.step5Control();
			});
			$('#mail_address').on('change', (e) => {
				FORM_OBJ.mail_address = $(e.currentTarget).val();
				A.step4Control();
				A.step5Control();
			});

			// console.log(FORM_OBJ.history, FORM_OBJ.corporatehistory, FORM_OBJ.name_1history, FORM_OBJ.name_2history, FORM_OBJ.read_1history, FORM_OBJ.read_2history, FORM_OBJ.postalhistory, FORM_OBJ.addresshistory, FORM_OBJ.phonehistory,  FORM_OBJ.mail_address)


			$('#form-step4 .form-block__pager-btn--prev').on('click', () => {
				A.stepHide($('#form-step4'));
				$('.step-list li').removeClass('is-active');
				if(FORM_OBJ.fileNumber <= 2 || FORM_OBJ.inputSelect == 'false'){
					A.stepFadeIn($('#form-step3'));
				} else {
					A.stepFadeIn($('#form-step2'));
				}
				$('.step-list li').eq($('.form-block__panel.is-active').index()).addClass('is-active');
			});

			$('#form-step4 .form-block__pager-btn--next').on('click', () => {
				A.stepHide($('#form-step4'));
				$('.form-block__panel').removeClass('is-active');
				$('.step-list li').removeClass('is-active');
				$('.step-list li').eq(4).addClass('is-clear');
				A.stepFadeIn($('#form-step5'));
				$('.step-list li').eq($('.form-block__panel.is-active').index()).addClass('is-active');
			});
		},

		step5Control(){
			if(FORM_OBJ.history && FORM_OBJ.corporate && FORM_OBJ.name_1 && FORM_OBJ.name_2 && FORM_OBJ.read_1 && FORM_OBJ.read_2 && FORM_OBJ.postal && FORM_OBJ.address && FORM_OBJ.phone &&  FORM_OBJ.mail_address){
				$('.step-list li').eq(5).addClass('is-clear');
				$('.step-list li').eq(6).addClass('is-clear');
				$('#form-step5 .form-block__pager-btn--next').removeClass('is-no-click');
				$('#form-step5 .form-block__pager__validate-txt').addClass('txt-hidden');
			}
		},
		step5(){
			const A = this;

			$('#form-step5 .form-block__pager-btn--prev').on('click', () => {
				A.stepHide($('#form-step5'));
				A.stepFadeIn($('#form-step4'));
				$('.step-list li').removeClass('is-active');
				$('.step-list li').eq($('.form-block__panel.is-active').index()).addClass('is-active');
			});

			$('#form-step5 .form-block__pager-btn--next').on('click', () => {
				A.stepHide($('#form-step5'));
				$('.form-block__panel').removeClass('is-active');
				$('.step-list li').removeClass('is-active');
				$('.step-list li').eq(5).addClass('is-clear');
				A.stepFadeIn($('#form-step6'));
				$('.step-list li').eq($('.form-block__panel.is-active').index()).addClass('is-active');
			});
		},

		step6Control(){
			if($('#concent').prop('checked') && $('#caution_concent').prop('checked')){
				$('#form-step6 .p-submit-btn input').removeClass('is-no-click');
				$('#form-step6 .form-block__pager__validate-txt').addClass('txt-hidden');
			} else {
				$('#form-step6 .p-submit-btn').addClass('is-no-click');
				$('#form-step6 .form-block__pager__validate-txt').removeClass('txt-hidden');
			}
		},

		step6(){
			const A = this;

			$('#concent').on('change', (e) => {
				A.step6Control();
			});
			$('#caution_concent').on('change', (e) => {
				A.step6Control();
			});

			$('#form-step6 .form-block__pager-btn--prev').on('click', () => {
				A.stepHide($('#form-step6'));
				A.stepFadeIn($('#form-step4'));
				$('.step-list li').removeClass('is-active');
				$('.step-list li').eq($('.form-block__panel.is-active').index()).addClass('is-active');
			});

		},







  }

  obj.init();
}


export {orderPage};