<template>
  <footer class="l-footer">
		<div class="l-inner-wrap">
			<ul class="l-footer__nav l-footer__nav--1">
				<template v-for="data in navData.footerNav1">
					<li v-if="data.class" :key="data.url">
						<a :class="data.class" :href="data.url">{{data.txt}}</a>
					</li>
					<li v-else :key="data.url">
						<a :href="data.url">{{data.txt}}</a>
					</li>
				</template>
			</ul>
			<ul class="l-footer__nav l-footer__nav--2">
				<li v-for="data in navData.footerNav2" :key="data.id">
					<a :href="data.url">{{data.txt}}</a>
				</li>
			</ul>
			<div class="l-footer__copy-block">
				<p class="l-footer__copy">&copy; 2015-{{copyrightYear}} Typing Base</p>
			</div>
		</div>
	</footer>
</template>

<script>
import navData from './navData';
export default {
  data: () => {
    return {
      message: 'footer',
			navData: [],
			copyrightYear: null
    }
  },
	created(){
		this.navData = navData;

		this.copyrightYear = this.getYear();

		console.log(this.copyrightYear);

	},
	methods: {
		getYear(){
			const data = new Date();
			const year = data.getFullYear();

			return year;
		}
}
};
</script>

<style scoped lang="scss">
@use "../../../scss/variable/define" as *;
@use "../../../scss/mixin/mixin" as *;
@use "sass:math";

.l-footer{
	padding: 38px 0;
	background-color: #c9cbca;
	color: #595757;
	position: relative;
	@media only screen and (max-width: $breakpoint){
		padding: vw(20) 0;
	}
	&__nav{
		@include flex($justify: flex-start);
		@media only screen and (max-width: $breakpoint){
			justify-content: center;
		}
		a{
			color: #595757;
		}
		&--1{
			li{
				@media only screen and (max-width: $breakpoint){
					margin: 0.2em 1em;
				}
				&:nth-child(n+2){
					@media only screen and (min-width: $breakpoint + 1){
						margin-left: 2em;
					}
				}
				a{
					font-size: 12px;
					@media only screen and (max-width: $breakpoint){
						font-size: vw(12);
					}
				}
			}
		}

		&--2{
			margin-top: 10px;
			@media only screen and (max-width: $breakpoint){
				margin-top: vw(20);
				padding-top: vw(20);
				border-top: 1px solid rgba(#000, 0.2);
			}
			li{
				position: relative;
				&:nth-child(n+2){
					margin-left: 1.5em;
					@media only screen and (max-width: $breakpoint){
						margin: 0 0.5em;
					}
					&::before{
						content: '/';
						display: inline-block;
						position: relative;
						left: -0.9em;
						font-size: 10px;
						@media only screen and (max-width: $breakpoint){
							font-size: vw(10);
							left: -0.5em;
						}
					}
				}
				a{
					font-size: 10px;
					@media only screen and (max-width: $breakpoint){
						font-size: vw(10);
					}
				}
			}
		}
	}

	&__copy-block{
		margin-top: 30px;
		text-align: right;
		font-size: 10px;
		@media only screen and (max-width: $breakpoint){
			margin-top: vw(30);
			text-align: center;
			font-size: vw(10);
		}
	}

}

.is-pc{
	.l-footer{
		a{
			&:hover{
				text-decoration: underline;
			}
		}
	}
}
</style>