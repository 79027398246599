export default {
	mainNav: [
		{
			txt: 'タイピングベースとは',
			url: '/#main-content',
			class : 'to-anker'
		},
		{
			txt: '料金プラン',
			url: '/price/'
		},
		{
			txt: 'ご依頼について',
			url: '/process/',
			dorpDown: [
				{
					txt: 'ご依頼の流れ',
					url: '/process/',
				},
				{
					txt: 'ご依頼の注意事項',
					url: '/notes/',
				},
				{
					txt: 'お受けできないご依頼',
					url: '/notaccepted/',
				}
			]
		},
		{
			txt: '完成品サンプル',
			url: '/sample/'
		},
		{
			txt: 'スタッフの声',
			url: '/staff/'
		}
	],
	contactNav: [
		{
			txt: 'お問い合わせ',
			url: '/contact/'
		},
		{
			txt: 'ご注文フォーム',
			url: '/order/'
		}
	],


	footerNav1: [
		{
			txt: 'タイピングベースとは',
			url: '/#main-content',
			class : 'to-anker'
		},
		{
			txt: '料金プラン',
			url: '/price/'
		},
		{
			txt: 'ご依頼について',
			url: '/process/'
		},
		{
			txt: '完成品サンプル',
			url: '/sample/'
		},
		{
			txt: 'スタッフの声',
			url: '/staff/'
		},
	],

	footerNav2: [
		{
			txt: 'タイピングベースTOP',
			url: '/'
		},
		{
			txt: '更新情報',
			url: '/info/'
		},
		{
			txt: 'ブログ',
			url: '/blog/'
		},
		{
			txt: '会社概要',
			url: '/company/'
		},
		{
			txt: '特定商取引法に基づく表記',
			url: '/term/'
		},
		{
			txt: 'プライバシーポリシー ',
			url: '/privacy/'
		},
		{
			txt: 'よくあるご質問',
			url: '/qa/'
		},
		{
			txt: 'お支払について',
			url: '/bill/'
		},
		// {
		// 	txt: '採用情報',
		// 	url: '/@@@/'
		// },
	],
}

