var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header-content" },
    [
      _c("Header", {
        ref: "child",
        attrs: { scrollTop: _vm.scrollTop, open: _vm.open },
        on: { "parent-event": _vm.navOp },
      }),
      _vm._v(" "),
      _c("SpGrayLayer", {
        ref: "child",
        attrs: { scrollTop: _vm.scrollTop, open: _vm.open },
        on: { "parent-event": _vm.navOp },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }