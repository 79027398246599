<template>
	<div class="modal-quote p-modal top" id="modal-quote">
		<div class="p-modal__inner">
			<div class="p-modal__content modal-quote__outer">
				<div class="modal-quote__inner">

					<div class="modal-quote__select-block">
						<div class="modal-quote__select-col">
							<label class="modal-quote__select-label" for="modal-quote-hour">データ時間</label>

							<div class="modal-quote__most-outer">
								<div class="modal-quote__select-outer modal-quote__select-outer--min">
									<select class="modal-quote__form-select modal-quote__form-select--min modal-quote__form-select--hour" name="modal-quote-hour" id="modal-quote-hour" @change="set()">
									</select>
								</div>
								<span class="txt">時間</span>
								<div class="modal-quote__select-outer modal-quote__select-outer--min">
									<select class="modal-quote__form-select modal-quote__form-select--min modal-quote__form-select--minutes" name="modal-quote-minutes" id="modal-quote-minutes" @change="set()">
									</select>
								</div>
								<span class="txt">分</span>
								<div class="modal-quote__select-outer modal-quote__select-outer--min">
									<select class="modal-quote__form-select modal-quote__form-select--min modal-quote__form-select--seconds" name="modal-quote-seconds" id="modal-quote-seconds" @change="set()">
									</select>
								</div>
								<span class="txt">秒</span>
							</div>

						</div>
					</div>

					<div class="modal-quote__select-block">
						<div class="modal-quote__select-col">
							<label class="modal-quote__select-label" for="modal-quote-plan">納期プラン</label>
							<div class="modal-quote__select-outer">
								<select class="modal-quote__form-select modal-quote__form-select--plan" name="modal-quote-plan" id="modal-quote-plan" @change="set()">
									<option value="0">レギュラープラン</option>
									<option value="1">ゆっくりプラン</option>
									<option value="2">特急プラン</option>
									<option value="3">翌日納品プラン</option>
									<option value="4">当日納品プラン</option>
								</select>
							</div>
						</div>
					</div>

					<div class="modal-quote__select-block">
						<div class="modal-quote__select-col">
							<label class="modal-quote__select-label" for="modal-quote-type">文字起こし形式</label>
							<div class="modal-quote__select-outer">
								<select class="modal-quote__form-select modal-quote__form-select--type" name="modal-quote-type" id="modal-quote-type" @change="set()">
									<option value="0">ケバ取り 雰囲気再現型</option>
									<option value="1">きれい仕上げ</option>
									<option value="2">記録型</option>
									<option value="3">素起こし</option>
								</select>
							</div>
						</div>
					</div>

					<div class="modal-quote__select-block">
						<div class="modal-quote__select-col">
							<label class="modal-quote__select-label" for="modal-quote-wasya">話者表記人数</label>
							<div class="modal-quote__select-outer">
								<select class="modal-quote__form-select modal-quote__form-select--wasya" name="modal-quote-wasya" id="modal-quote-wasya" @change="set()"></select>
							</div>
						</div>
						<ul class="modal-quote__note-list">
							<li>※6名以上の話者表記は対応しておりません。</li>
							<li>※翌日納品プランと当日納品プランの話者表記は2人までとなります。</li>
							<li>※翌日納品プランと当日納品プランの文字起こし形式は、ケバ取り雰囲気型ときれい仕上げのみとなります。</li>
						</ul>
					</div>

					<div class="modal-quote__preview-area">
						<div v-if="formData.totalTime" class="modal-quote__preview-block">
							<dl class="modal-quote__preview-dl">
								<div class="modal-quote__preview-item modal-quote__preview-item--time">
									<dt>
										<span class="label">データ時間</span>
									</dt>
									<dd><span class="time">{{ formData.totalTime }}<span class="fz-minutes">分</span></span></dd>
								</div>

								<div class="modal-quote__preview-item modal-quote__preview-item--type">
									<dt>
										<span class="label">料金プラン</span>
									</dt>
									<dd>
										<span class="set-label">{{ formData.typeName }} {{ formData.planName }}</span><br>
										<span class="set-price">{{ formData.planPrice }}<span class="fz-en">円</span> × {{ formData.totalTime }}<span class="fz-minutes">分</span></span>
									</dd>
								</div>

								<div class="modal-quote__preview-item modal-quote__preview-item--wasya">
									<dt>
										<span class="label">話者表記人数</span>
									</dt>
									<dd><span class="set-label">{{ formData.wasya }}人</span><br>
									<span class="set-price">{{ formData.wasyaPrice }}<span class="fz-en">円</span> × {{ formData.totalTime }}<span class="fz-minutes">分</span></span></dd>
								</div>

								<div class="modal-quote__preview-item modal-quote__preview-item--tax">
									<dt>
										<span class="label">消費税</span>
									</dt>
									<dd><span class="set-price">{{ formData.tax }}<span class="fz-en">円</span></span></dd>
								</div>

								<div class="modal-quote__preview-item modal-quote__preview-item--total">
									<dt>
										<span class="label">合計</span>
									</dt>
									<dd><span class="total-price">{{ formData.totalTaxinPrice }}</span><span class="fz-en">円（税込）</span></dd>
								</div>

							</dl>
						</div>
					</div>


					<p class="last-note">
						あくまでも概算のお見積り料金となります。<br>
						正式なお見積りはデータ確認後となります。
					</p>

					<div class="p-btn-orange-bg"><a class="quote-to-order" href="/order/">ご注文</a></div>

				</div>

				<div class="p-modal__close-btn"><div class="p-modal__close-btn__inner"></div></div>
			</div>
			<!-- /p-modal__content -->
		</div>

	</div>
</template>

<script>
import PRICE from '../../_priceData';
import PLAN from '../../_planData';
import OPTION from '../../_optionData';
import { Util } from '../../_util';
export default {
  data: () => {
    return {
      message: 'modal-quote',

			formData: {
				totalPrice: 0,
				totalTaxinPrice: 0,
				tax: 0,
				hour: 0,
				minutes: 0,
				seconds: 0,
				totalTime: 0,
				plan: 0,
				planName: null,
				type: 0,
				typeName: null,
				wasya: 0,
				wasyaPrice: 0,
				planPrice: 0,

			}
    }
  },
	created(){

	},

	mounted(){
		const A = this;

		const util = new Util();

		util.selectSet('.modal-quote__form-select--hour', 0, 20);
		util.selectSet('.modal-quote__form-select--minutes', 0, 59);
		util.selectSet('.modal-quote__form-select--seconds', 0, 59);
		util.selectSet('.modal-quote__form-select--wasya', 0, 5);

		let scrVal = 0;

		$('.js-quote-btn').on('click', (e) => {
			e.preventDefault();

			scrVal = $(window).scrollTop();

			$('#wrap').css('top', - scrVal);
			$('#modal-quote').addClass('is-active');
			$('body').addClass('is-modal-op');
		});

		$('.p-modal__close-btn, .p-modal').on('click', (e) => {
			$('#modal-quote').removeClass('is-active');
			$('body').removeClass('is-modal-op');
			$('#wrap').removeAttr('style');
			$(window).scrollTop(scrVal);
		});

		$('.p-modal__content').on('click', (e) => {
			e.stopPropagation();
		});
	},

	methods: {

		set(){
			this.timeSet();
			this.otherSet();
			this.priceSet();
		},
		timeSet(){
			const A = this;

			A.formData.hour = parseInt($('.modal-quote__form-select--hour').val());
			A.formData.minutes = parseInt($('.modal-quote__form-select--minutes').val());
			A.formData.seconds = parseInt($('.modal-quote__form-select--seconds').val());
			A.formData.totalTime = (A.formData.hour * 60)  + A.formData.minutes + (A.formData.seconds ? 1 : 0);
		},
		otherSet(){
			const A = this;

			A.formData.type = parseInt($('.modal-quote__form-select--type').val());
			A.formData.plan = parseInt($('.modal-quote__form-select--plan').val());
			A.formData.wasya = parseInt($('.modal-quote__form-select--wasya').val());


			if(3 <= A.formData.plan){
				// 翌日納品プラン、または当日納品プランを選択した場合
				$('.modal-quote__form-select--wasya option').each((i, el) => {
					if( 3 <= parseInt($(el).attr('value'))){
						$(el).prop('disabled', true);
					}
				});

				if(3 <= A.formData.wasya){
					$('.modal-quote__form-select--wasya option[value="2"]').prop('selected', true);
					A.formData.wasya = 2;
				}


			} else {
				$('.modal-quote__form-select--wasya option').each((i, el) => {
					$(el).prop('disabled', false);
				});
			}

			if(2 <= A.formData.type){
				$('.modal-quote__form-select--plan option').each((i, el) => {
					if( 3 <= parseInt($(el).attr('value'))){
						$(el).prop('disabled', true);
					}
				});
			} else {
				$('.modal-quote__form-select--plan option').each((i, el) => {
					$(el).prop('disabled', false);
				});
			}

			if(3 <= A.formData.plan){
				$('.modal-quote__form-select--type option').each((i, el) => {
					if( 2 <= parseInt($(el).attr('value'))){
						$(el).prop('disabled', true);
					}
				});
			} else {
				$('.modal-quote__form-select--type option').each((i, el) => {
					$(el).prop('disabled', false);
				});
			}

		},

		priceSet(){
			const A = this;

			if(!A.formData.totalTime) return;

			A.formData.totalPrice = (PRICE[A.formData.type].price[A.formData.plan] * A.formData.totalTime) + (OPTION.wasya[A.formData.wasya] * A.formData.totalTime);

			A.formData.totalTaxinPrice = Math.floor((A.formData.totalPrice * 1.1)).toLocaleString();
			A.formData.tax =  Math.floor((A.formData.totalPrice * 1.1) - A.formData.totalPrice).toLocaleString();

			A.formData.planPrice = PRICE[A.formData.type].price[A.formData.plan];
			A.formData.typeName = PRICE[A.formData.type].name;
			A.formData.planName = PLAN[A.formData.plan];
			A.formData.wasyaPrice =OPTION.wasya[A.formData.wasya];


		},
}
};
</script>

<style scoped lang="scss">
@use "../../../scss/variable/define" as *;
@use "../../../scss/mixin/mixin" as *;
@use "sass:math";

.modal-quote{
	// opacity: 1;
	// pointer-events: auto;
	&__outer{
		width: 660px;
		margin-left: auto;
		margin-right: auto;
		padding: 20px;
		box-shadow: 0px 0px 16px -0px rgba(0,0,0,0.4);
		background-color: #fff;
		position: relative;
		@media only screen and (max-width: $breakpoint){
			width: 100%;
			padding: vw(10);
		}
	}

	&__inner{
		border: 2px solid $c_orange_1;
		padding: 80px 70px 40px;
		@media only screen and (max-width: $breakpoint){
			padding: vw(40) vw(20);
		}
	}

	&__select-block{
		&:nth-child(n+2){
			margin-top: 40px;
		}
	}

	&__select-col{
		@media only screen and (min-width: $breakpoint + 1){
			@include flex($justify: flex-start, $align: center);
		}
	}

	&__select-label{
		width: 130px;
		display: block;
		color: $c_orange_1;
		font-size: 13px;
		font-weight: 700;
		@media only screen and (max-width: $breakpoint){
			width: 100%;
			font-size: vw(13);
		}
	}

	&__select-outer{
		margin-left: 20px;
		position: relative;
		@media only screen and (max-width: $breakpoint){
			margin-left: 0;
		}
		&::after{
			content: '';
			display: block;
			width: 10px;
			height: 10px;
			border-bottom: 2px solid $c_orange_1;
			border-right: 2px solid $c_orange_1;
			box-sizing: border-box;
			transform: rotate(45deg);
			position: absolute;
			top: -4px;
			bottom: 0;
			right: 15px;
			margin: auto;
			@media only screen and (max-width: $breakpoint){
				width: vw(10);
				height: vw(10);
				top: vw(-4);
			}
		}
		&--min{
			&::after{
				width: 7px;
				height: 7px;
				right: 8px;
				@media only screen and (max-width: $breakpoint){
					width: vw(7);
					height: vw(7);
					right: vw(7);
				}
			}
		}
	}

	&__form-select{
		width: 290px;
		height: 30px;
		padding: 0 10px;
		border: 2px solid $c_orange_1;
		color: $base_fontColor;
		font-size: 13px;
		font-weight: 700;
		appearance: none;
		display: block;
		@media only screen and (max-width: $breakpoint){
			width: 100%;
			font-size: vw(13);
			padding: 0 vw(10);
		}

		&--min{
			width: 60px;
			@media only screen and (max-width: $breakpoint){
				width: vw(60);
			}
		}
	}
	&__note{
		margin-top: 1.2em;
		font-size: 11px;
		font-weight: 700;
		color: $c_orange_1;
		text-indent: -1em;
		padding-left: 1em;
		@media only screen and (max-width: $breakpoint){
			font-size: vw(8);
		}
	}

	&__note-list{
		margin-top: 1.2em;
		font-size: 11px;
		font-weight: 700;
		color: $c_orange_1;
		@media only screen and (max-width: $breakpoint){
			font-size: vw(8);
		}

		li{
			text-indent: -1em;
			padding-left: 1em;
		}
	}

	&__most-outer{
		width: 320px;
		margin-left: 20px;
		@include flex($justify: flex-start);
		@media only screen and (max-width: $breakpoint){
			width: 100%;
			margin-left: 0;
			margin-top: vw(10);
		}
		.modal-quote__select-outer{
			margin-left: 0;
		}

		.txt{
			display: block;
			margin: 0 1em 0 .4em;
		}
	}

	&__preview-block{
		margin-top: 30px;
		padding-top: 30px;
		border-top: 1px solid $c_orange_1;
		@media only screen and (max-width: $breakpoint){
			margin-top: vw(30);
			padding-top: vw(30);
		}
	}

	&__preview-item{
		&:nth-child(n+2){
			margin-top: 0.5em;
			@media only screen and (max-width: $breakpoint){
				margin-top: 1em;
			}
		}
		&:nth-child(1){
			@include flex($justify: flex-start);
			dt{
				margin-right: 2em;
			}
			dd{
				font-size: 15px;
				font-weight: 700;
				@media only screen and (max-width: $breakpoint){
					font-size: vw(14);
				}
			}
		}
		.label{
			font-size: 12px;
			@media only screen and (max-width: $breakpoint){
				font-size: vw(10);
			}
		}
		.set-label{
			font-size: 15px;
			font-weight: 700;
			padding-right: 0.5em;
			@media only screen and (max-width: $breakpoint){
				font-size: vw(14);
			}
		}
		.set-price{
			font-size: 15px;
			font-weight: 700;
			@media only screen and (max-width: $breakpoint){
				font-size: vw(15);
			}
		}
		.fz-en{
			font-size: 12px;
			@media only screen and (max-width: $breakpoint){
				font-size: vw(10);
			}
		}
		.fz-minutes{
			font-size: 12px;
			@media only screen and (max-width: $breakpoint){
				font-size: vw(10);
			}
		}

		.total-price{
			font-size: 24px;
			font-weight: 700;
			@media only screen and (max-width: $breakpoint){
				font-size: vw(24);
			}
		}
	}

	.last-note{
		margin-top: 40px;
		font-size: 13px;
		font-weight: 700;
		padding-top: 30px;
		border-top: 1px solid $c_orange_1;
		color: $c_orange_1;
		text-align: center;
		@media only screen and (max-width: $breakpoint){
			margin-top: vw(20);
			padding-top: vw(20);
			font-size: vw(13);
		}
	}


	.p-btn-orange-bg{
		width: 100%;
		height: 50px;
		margin-top: 30px;
		@media only screen and (max-width: $breakpoint){
			height: vw(40);
			margin-top: vw(30);
		}
		a{
			border-width: 2px;
		}
	}
}
</style>