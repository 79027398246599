<template>
	<div class="header-content">
		<Header :scrollTop="scrollTop" :open="open" ref="child" @parent-event="navOp"></Header>
		<SpGrayLayer :scrollTop="scrollTop" :open="open" ref="child" @parent-event="navOp"></SpGrayLayer>
	</div>
</template>

<script>
import OBJ from '../../_obj';
import Header from './header.vue';
import SpGrayLayer from './spGrayLayer.vue';

export default {
  components: {
    Header,
		SpGrayLayer,
  },

	data: () => {
    return {
			scrollTop: 0,
			open: false,
    }
  },

	mounted(){
		const body = document.querySelector('body');
		const wrap = document.getElementById('wrap');

		window.onresize = () => {
			if(window.innerWidth > OBJ.BP){
				wrap.removeAttribute('style');
				body.classList.remove('is-nav-op');
				// window.scrollTo(0, this.scrollTop);
				this.open = false;
			}
		}
	},

	methods: {
		navOp(){
			const body = document.querySelector('body');
			const wrap = document.getElementById('wrap');
			const spNavContent = document.querySelector('.l-header__nav-block');

			if(this.open){
				wrap.removeAttribute('style');
				body.classList.remove('is-nav-op');
				window.scrollTo(0, this.scrollTop);
				this.open = false;
			} else {
				this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

				body.classList.add('is-nav-op');
				spNavContent.scrollTop = 0;
				// console.log(this.scrollTop);
				wrap.style.top = -this.scrollTop + 'px';
				this.open = true;

			}

		},

	}
}

</script>

<style scoped lang="scss">
@use "../../../scss/variable/define" as *;
@use "../../../scss/mixin/mixin" as *;
@use "sass:math";
</style>