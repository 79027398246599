<template>
	<div class="post-wrap single-fade to-up">
		<div class="post-block">
			<div class="post-block__header"><a href="/info/">更新情報</a></div>
			<ul class="post-block__list">
				<li v-for="(data, infoIndex ) in infoData" class="post-block__list-item" :key="infoIndex">
					<a class="u-hv-o" :href="data.link">
						<p class="post-block__date u-ff-hel">{{ data.date }}</p>
						<p class="post-block__ttl">{{ data.title }}</p>
					</a>
				</li>
			</ul>
		</div>

		<div class="post-block">
			<div class="post-block__header"><a href="/blog/">タイピングベースブログ</a></div>
			<ul class="post-block__list">
				<li v-for="(data, blogIndex ) in blogData" class="post-block__list-item" :key="blogIndex">
					<a class="u-hv-o" :href="data.link">
						<p class="post-block__date u-ff-hel">{{ data.date }}</p>
						<p class="post-block__ttl">{{ data.title }}</p>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
export default {
  data: () => {
    return {
      message: 'post',
			infoData: [],
			blogData: [],
    }
  },
	created(){
		this.dataGet();

	},
	methods: {
		dataGet(){
			const infoUrl = '/wp-json/wp/v2/posts?_embed&categories=3&per_page=5&_fields=title,link,date';
			const blogUrl = '/wp-json/wp/v2/posts?_embed&categories=2&per_page=5&_fields=title,link,date';

			axios.get(infoUrl).then(response => {
				response.data.forEach((el,i) => {
					const y = el.date.split('-')[0];
					const m = el.date.split('-')[1];
					const d = el.date.split('-')[2].split('T')[0];
					this.infoData.push({
						title: el.title.rendered.replace('<br>', ''),
						link: el.link,
						date: `${y}/${m}/${d}`
					});
				});

			});

			axios.get(blogUrl).then(response => {
				response.data.forEach((el,i) => {
					const y = el.date.split('-')[0];
					const m = el.date.split('-')[1];
					const d = el.date.split('-')[2].split('T')[0];
					this.blogData.push({
						title: el.title.rendered.replace('<br>', ''),
						link: el.link,
						date: `${y}/${m}/${d}`
					});
				});
			});
		}
	}
};
</script>

<style scoped lang="scss">
@use "../../../scss/variable/define" as *;
@use "../../../scss/mixin/mixin" as *;
@use "sass:math";


</style>