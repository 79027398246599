import OBJ from '../../_obj';
import { Util } from '../../_util';
import { gsap } from "gsap";


const postPage = () => {
  const obj = {

    init() {
    	const A = this;

			if(OBJ.BP < $(window).width()){
				A.sideFixed();
			}

			A.iframeWrap();
			A.imageAddClass();


    },

		loading(){

		},


		setSelector(){
			this.$body = $('body');


		},


		sideFixed(){
			const $target = $('.side-content');
			const $triggerEl = $('.main-content');

			console.log('実行中')

			$(window).on('scroll', (e) => {
				const wH = $(e.currentTarget).height();
				const val = $(e.currentTarget).scrollTop();
				const offset = $triggerEl.offset().top;
				const mT = parseInt($triggerEl.css('margin-top'));
				const hH = $('.l-header.is-min').outerHeight();
				const triggerH = $triggerEl.height();

				const posi = Math.floor(offset - hH - mT)

				if( triggerH <= $target.outerHeight()){
					$target.addClass('is-static');
					return;
				}

				if(posi < val){
					$target.addClass('is-fixed');
				} else {
					$target.removeClass('is-fixed');
				}

				if((offset + triggerH) - (wH) + (wH - $target.outerHeight() - 150 + parseInt($target.css('margin-top'))) < val){
					$target.addClass('is-absolute');
				} else {
					$target.removeClass('is-absolute');
				}
			}).trigger('scroll');


		},

		iframeWrap(){
			$('iframe').each((i,el) => {
				// console.log('iframe', i,el);
				$(el).wrap('<div class="u-iframe-w-100">');
			});
		},

		imageAddClass(){
			$('.article__content p').each((i,el) => {
				console.log('img', i,el);
				if($(el).find('img').length){
					$(el).addClass('article-img');
				}
			});
		}


  }

  obj.init();
}


export {postPage};