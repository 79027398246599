<template>
	<div class="l-graylayer" @click="navOp"></div>
</template>

<script>
export default {
	props: ['scrollTop', 'open'],

  data: () => {
    return {
      message: 'graylayer',
    }
  },

	methods: {
		navOp(){
			this.$emit('parent-event');
		}
	}
};
</script>


<style scoped lang="scss">
@use "../../../scss/variable/define" as *;
@use "../../../scss/mixin/mixin" as *;
@use "sass:math";

.l-graylayer{
	width: 100%;
	height: 100%;
	background-color: rgba(#000, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
	opacity: 0;
	pointer-events: none;
}
</style>