export default {
	daysLater: null, //今日から納品希望日までの日数
	preferredDate: null, //納品希望日
	selectDateHoliday: false, //土日祝日希望か
	todayDelivery: true,
	tomorrowDelivery: true,

	inputSelect: null,
	fileNumber: null,
	voiceDetail: null,
	xlsxDownload: null,
	fileName1: null,
	fileName2: null,

	ttime1_s_h: 0,
	ttime1_s_m: 0,
	ttime1_s_s: 0,
	ttime1_s_SET_M: 0,

	ttime1_e_h: 0,
	ttime1_e_m: 0,
	ttime1_e_s: 0,
	ttime1_e_SET_M: 0,

	ttime1_Total: 0,

	ttime2_s_h: 0,
	ttime2_s_m: 0,
	ttime2_s_s: 0,
	ttime2_s_SET_M: 0,

	ttime2_e_h: 0,
	ttime2_e_m: 0,
	ttime2_e_s: 0,
	ttime2_e_SET_M: 0,

	ttime2_Total: 0,

	totalTime: 0,

	speakerNumber1: null, //string 文字列だから気をつけて
	speakerNumber2: null, //string 文字列だから気をつけて

	plan: null,

	format1: null,
	format2: null,

	talkNumber1: null,
	talkNumber2: null,

	expertise: null,
	timeStamp: null,
	timeStampSrt: null,
	selfRole: null,
	webData: null,
	holidayDelivery: null,
	returnData: null,

	history: null,
	corporate: null,
	name_1: null,
	name_2: null,
	read_1: null,
	read_2: null,
	postal: null,
	address: null,
	phone: null,
	mail_address: null,

	concent: null,
	caution_concent: null,



	document: null,




	format1Before: null,
	format2Before: null,
	talkNumber1Before: null,
	talkNumber2Before: null,
}