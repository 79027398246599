
import OBJ from './_obj';
import {ua} from './_ua';
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
import Vue from 'vue';
import headerSpNav from './vue/layouts/headerSpNav.vue'
import footer from'./vue/layouts/footer.vue';
import postList from'./vue/layouts/postList.vue';
import modalQuote from'./vue/layouts/modalQuote.vue';
import '../scss/main.scss';

import {Util} from './_util';
import {modal} from './_modal';
import {nav} from './_nav';
// import {modalQuote} from './_modalQuote';

import {indexPage} from './pages/index/_script'
import {pricePage} from './pages/price/_script'
import {processPage} from './pages/process/_script'
import {samplePage} from './pages/sample/_script'
import {orderPage} from './pages/order/_script'
import {contactPage} from './pages/contact/_script'
import {postPage} from './pages/post/_script'



$(function(){
	new ua();

	new Vue({
		el: '#vue-header',
		render: (h) => h(headerSpNav),
	});
	new Vue({
		el: '#vue-footer',
		render: (h) => h(footer),
	});
	new Vue({
		el: '#vue-post-list',
		render: (h) => h(postList),
	});
	new Vue({
		el: '#vue-modal-quote',
		render: (h) => h(modalQuote),
	});

	new nav();

	const util = new Util();

	$('a[href^="#"]').on('click',(e)=>{
    e.preventDefault();
    const $this = $(e.currentTarget);
		const id = $this.attr('href').split('#')[1];
    const diff = $this.data('diff');

		if($this.hasClass('non-scroll') || $this.hasClass('js-modal-btn') || $this.hasClass('js-video-modal-btn')) return;
    util.ankerScroll(id,diff);
  });

	util.staggerFade('.stagger-fade-group','.stagger-fade', -30);
	util.singleFade('.single-fade', -30);


	const pagesJs = () => {
		const $body = document.querySelector('body');


		if($body.classList.contains('top-page')){
			indexPage();
		}

		if($body.classList.contains('price-page')){
			pricePage();
		}

		if($body.classList.contains('sample-page')){
			samplePage();
		}

		if($body.classList.contains('order-page')){
			orderPage();
		}

		if($body.classList.contains('contact-page')){
			contactPage();
		}

		if($body.classList.contains('post-page')){
			postPage();
		}


		if($body.classList.contains('process-page') || $body.classList.contains('notes-page') || $body.classList.contains('notaccepted-page')){
			processPage();
		}
	}

	pagesJs();


});
