import OBJ from '../../_obj';
import { Util } from '../../_util';
import { gsap } from "gsap";


const indexPage = () => {
  const obj = {

    init() {
    	const A = this;


			if($('body').hasClass('maintenance-page')){
				A.navRemove();
			} else {
				A.videoLoad();
			}




    },

		loading(){

		},

		videoLoad(_callback){
			const video = document.getElementById('kv-video');
			const videoSp = document.getElementById('kv-video_sp');
			const src = video.dataset.src;
			const srcSp = videoSp.dataset.src;

			if(!OBJ.isSP){
				video.setAttribute('src', src);
				setTimeout(() => {
					video.play();
				},500);
			} else {
				videoSp.setAttribute('src', srcSp);
				setTimeout(() => {
					videoSp.play();
				},500);
			}

			$('.kv').addClass('is-active');




			// video.load();

			// video.addEventListener('loadedmetadata', function(e) {
			// 	_callback();
			// });
		},

		navRemove(){
			$('.l-header .quote').remove();
			$('.l-header__hum-btn').remove();
			$('.l-header__nav-block').remove();
			$('.l-graylayer').remove();
		}
	}

  obj.init();
}


export {indexPage};