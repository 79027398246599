var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal-quote p-modal top", attrs: { id: "modal-quote" } },
    [
      _c("div", { staticClass: "p-modal__inner" }, [
        _c("div", { staticClass: "p-modal__content modal-quote__outer" }, [
          _c("div", { staticClass: "modal-quote__inner" }, [
            _c("div", { staticClass: "modal-quote__select-block" }, [
              _c("div", { staticClass: "modal-quote__select-col" }, [
                _c(
                  "label",
                  {
                    staticClass: "modal-quote__select-label",
                    attrs: { for: "modal-quote-hour" },
                  },
                  [_vm._v("データ時間")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-quote__most-outer" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "modal-quote__select-outer modal-quote__select-outer--min",
                    },
                    [
                      _c("select", {
                        staticClass:
                          "modal-quote__form-select modal-quote__form-select--min modal-quote__form-select--hour",
                        attrs: {
                          name: "modal-quote-hour",
                          id: "modal-quote-hour",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.set()
                          },
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "txt" }, [_vm._v("時間")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "modal-quote__select-outer modal-quote__select-outer--min",
                    },
                    [
                      _c("select", {
                        staticClass:
                          "modal-quote__form-select modal-quote__form-select--min modal-quote__form-select--minutes",
                        attrs: {
                          name: "modal-quote-minutes",
                          id: "modal-quote-minutes",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.set()
                          },
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "txt" }, [_vm._v("分")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "modal-quote__select-outer modal-quote__select-outer--min",
                    },
                    [
                      _c("select", {
                        staticClass:
                          "modal-quote__form-select modal-quote__form-select--min modal-quote__form-select--seconds",
                        attrs: {
                          name: "modal-quote-seconds",
                          id: "modal-quote-seconds",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.set()
                          },
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "txt" }, [_vm._v("秒")]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-quote__select-block" }, [
              _c("div", { staticClass: "modal-quote__select-col" }, [
                _c(
                  "label",
                  {
                    staticClass: "modal-quote__select-label",
                    attrs: { for: "modal-quote-plan" },
                  },
                  [_vm._v("納期プラン")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-quote__select-outer" }, [
                  _c(
                    "select",
                    {
                      staticClass:
                        "modal-quote__form-select modal-quote__form-select--plan",
                      attrs: {
                        name: "modal-quote-plan",
                        id: "modal-quote-plan",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.set()
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "0" } }, [
                        _vm._v("レギュラープラン"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1" } }, [
                        _vm._v("ゆっくりプラン"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "2" } }, [
                        _vm._v("特急プラン"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "3" } }, [
                        _vm._v("翌日納品プラン"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "4" } }, [
                        _vm._v("当日納品プラン"),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-quote__select-block" }, [
              _c("div", { staticClass: "modal-quote__select-col" }, [
                _c(
                  "label",
                  {
                    staticClass: "modal-quote__select-label",
                    attrs: { for: "modal-quote-type" },
                  },
                  [_vm._v("文字起こし形式")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-quote__select-outer" }, [
                  _c(
                    "select",
                    {
                      staticClass:
                        "modal-quote__form-select modal-quote__form-select--type",
                      attrs: {
                        name: "modal-quote-type",
                        id: "modal-quote-type",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.set()
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "0" } }, [
                        _vm._v("ケバ取り 雰囲気再現型"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1" } }, [
                        _vm._v("きれい仕上げ"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "2" } }, [
                        _vm._v("記録型"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "3" } }, [
                        _vm._v("素起こし"),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-quote__select-block" }, [
              _c("div", { staticClass: "modal-quote__select-col" }, [
                _c(
                  "label",
                  {
                    staticClass: "modal-quote__select-label",
                    attrs: { for: "modal-quote-wasya" },
                  },
                  [_vm._v("話者表記人数")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-quote__select-outer" }, [
                  _c("select", {
                    staticClass:
                      "modal-quote__form-select modal-quote__form-select--wasya",
                    attrs: {
                      name: "modal-quote-wasya",
                      id: "modal-quote-wasya",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.set()
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _vm._m(0),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-quote__preview-area" }, [
              _vm.formData.totalTime
                ? _c("div", { staticClass: "modal-quote__preview-block" }, [
                    _c("dl", { staticClass: "modal-quote__preview-dl" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "modal-quote__preview-item modal-quote__preview-item--time",
                        },
                        [
                          _vm._m(1),
                          _vm._v(" "),
                          _c("dd", [
                            _c("span", { staticClass: "time" }, [
                              _vm._v(_vm._s(_vm.formData.totalTime)),
                              _c("span", { staticClass: "fz-minutes" }, [
                                _vm._v("分"),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "modal-quote__preview-item modal-quote__preview-item--type",
                        },
                        [
                          _vm._m(2),
                          _vm._v(" "),
                          _c("dd", [
                            _c("span", { staticClass: "set-label" }, [
                              _vm._v(
                                _vm._s(_vm.formData.typeName) +
                                  " " +
                                  _vm._s(_vm.formData.planName)
                              ),
                            ]),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", { staticClass: "set-price" }, [
                              _vm._v(_vm._s(_vm.formData.planPrice)),
                              _c("span", { staticClass: "fz-en" }, [
                                _vm._v("円"),
                              ]),
                              _vm._v(" × " + _vm._s(_vm.formData.totalTime)),
                              _c("span", { staticClass: "fz-minutes" }, [
                                _vm._v("分"),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "modal-quote__preview-item modal-quote__preview-item--wasya",
                        },
                        [
                          _vm._m(3),
                          _vm._v(" "),
                          _c("dd", [
                            _c("span", { staticClass: "set-label" }, [
                              _vm._v(_vm._s(_vm.formData.wasya) + "人"),
                            ]),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", { staticClass: "set-price" }, [
                              _vm._v(_vm._s(_vm.formData.wasyaPrice)),
                              _c("span", { staticClass: "fz-en" }, [
                                _vm._v("円"),
                              ]),
                              _vm._v(" × " + _vm._s(_vm.formData.totalTime)),
                              _c("span", { staticClass: "fz-minutes" }, [
                                _vm._v("分"),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "modal-quote__preview-item modal-quote__preview-item--tax",
                        },
                        [
                          _vm._m(4),
                          _vm._v(" "),
                          _c("dd", [
                            _c("span", { staticClass: "set-price" }, [
                              _vm._v(_vm._s(_vm.formData.tax)),
                              _c("span", { staticClass: "fz-en" }, [
                                _vm._v("円"),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "modal-quote__preview-item modal-quote__preview-item--total",
                        },
                        [
                          _vm._m(5),
                          _vm._v(" "),
                          _c("dd", [
                            _c("span", { staticClass: "total-price" }, [
                              _vm._v(_vm._s(_vm.formData.totalTaxinPrice)),
                            ]),
                            _c("span", { staticClass: "fz-en" }, [
                              _vm._v("円（税込）"),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm._m(6),
            _vm._v(" "),
            _vm._m(7),
          ]),
          _vm._v(" "),
          _vm._m(8),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "modal-quote__note-list" }, [
      _c("li", [_vm._v("※6名以上の話者表記は対応しておりません。")]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "※翌日納品プランと当日納品プランの話者表記は2人までとなります。"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "※翌日納品プランと当日納品プランの文字起こし形式は、ケバ取り雰囲気型ときれい仕上げのみとなります。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("span", { staticClass: "label" }, [_vm._v("データ時間")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("span", { staticClass: "label" }, [_vm._v("料金プラン")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("span", { staticClass: "label" }, [_vm._v("話者表記人数")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [_c("span", { staticClass: "label" }, [_vm._v("消費税")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [_c("span", { staticClass: "label" }, [_vm._v("合計")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "last-note" }, [
      _vm._v("\n\t\t\t\t\tあくまでも概算のお見積り料金となります。"),
      _c("br"),
      _vm._v("\n\t\t\t\t\t正式なお見積りはデータ確認後となります。\n\t\t\t\t"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "p-btn-orange-bg" }, [
      _c("a", { staticClass: "quote-to-order", attrs: { href: "/order/" } }, [
        _vm._v("ご注文"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "p-modal__close-btn" }, [
      _c("div", { staticClass: "p-modal__close-btn__inner" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }