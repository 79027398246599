var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "l-footer" }, [
    _c("div", { staticClass: "l-inner-wrap" }, [
      _c(
        "ul",
        { staticClass: "l-footer__nav l-footer__nav--1" },
        [
          _vm._l(_vm.navData.footerNav1, function (data) {
            return [
              data.class
                ? _c("li", { key: data.url }, [
                    _c("a", { class: data.class, attrs: { href: data.url } }, [
                      _vm._v(_vm._s(data.txt)),
                    ]),
                  ])
                : _c("li", { key: data.url }, [
                    _c("a", { attrs: { href: data.url } }, [
                      _vm._v(_vm._s(data.txt)),
                    ]),
                  ]),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "l-footer__nav l-footer__nav--2" },
        _vm._l(_vm.navData.footerNav2, function (data) {
          return _c("li", { key: data.id }, [
            _c("a", { attrs: { href: data.url } }, [_vm._v(_vm._s(data.txt))]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "l-footer__copy-block" }, [
        _c("p", { staticClass: "l-footer__copy" }, [
          _vm._v("© 2015-" + _vm._s(_vm.copyrightYear) + " Typing Base"),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }